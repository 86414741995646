import React from "react";
import List from "../../../shared/ui/list/list";

function WhyFmlIsStrongest() {
  return (
    <div>
      <h2>Итоги всероссийской олимпиады по математике 2021 года</h2>
      <img
        src={"/images/articles/finalOlympiadStage/results-2021.png"}
        alt=""
      />
      <h3>
        Бесплатный тренажёр для самостоятельной подготовки к поступлению в 5
        класс
      </h3>
      <List
        data={[
          "83 вступительные работы в 22 школы Москвы и Петербурга",
          "1500 задач",
          "2700 подсказок по неправильным ответам",
        ]}
      />
      <h3>Помощь от квалифицированных репетиторов</h3>
      <List
        data={[
          "Методика minimath239 по 180 темам",
          "4000 задач для домашних заданий",
          "Генератор задач, на которые нет ответов в интернете",
          "Собственная электронная доска",
        ]}
      />
      <p>
        Для начала обучения необходимо забронировать послать заявку на
        проведение двух ознакомительных уроков с Фефеловой Ольгой:
        minimath239@yandex.ru или whatsapp +7 (981) 682-86-83
      </p>
      <h3>Стоимость</h3>
      <List
        data={[
          "1500 рублей за 50 минут для индивидуальных занятий,",
          "2000 рублей для группы из 2-х учеников,",
          "2500 рублей для 3-х учеников",
        ]}
      />
      <h3>О наших преподавателях</h3>
      <a href={"/feedback"}>Отзывы</a> и{" "}
      <a href={"/achievements"}>результаты работ</a>
      <h3>Источники и прецеденты использования данных, указанных в статье</h3>
      <p> Страница итоги ВСОШ по математике: </p>
      <a href={"https://vsoshmath2022.edurm.ru/"}>
        https://vsoshmath2022.edurm.ru/
      </a>
      <p> Страница финала ВСОШ: </p>
      <a href={"https://olimpiada.ru/vos2022"}>https://olimpiada.ru/vos2022</a>
      <p> Поименный список победителей и призёров ФМЛ 239: </p>
      <a
        href={
          "https://239.ru/tpost/hj2tv8lbc1-pozdravlyaem-pobeditelei-i-prizerov-zakl"
        }
      >
        https://239.ru/tpost/hj2tv8lbc1-pozdravlyaem-pobeditelei-i-prizerov-zakl
      </a>
    </div>
  );
}

export default WhyFmlIsStrongest;
