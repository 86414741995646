import React, { useState } from "react";
import { AuthService } from "../../../APIService/AuthService";
import { UserService } from "../../../APIService/UserService";
import ConfirmDeleteAccountDialog from "../../../components/profile/dialog/ConfirmDeleteAccountDialog";
import ProfileCenteredButton from "../../../components/profile/ProfileCenteredButton";
import { useAuthContext } from "../../../context/authContext";
import styles from "./deleteAccountForm.module.scss";

export function DeleteAccountForm() {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const closeOnApply = async () => {
    setOpenDialog(false);
    if (authInfo.id > 0) {
      await UserService.deleteUser(authInfo.id);
      AuthService.logout();
    }
  };
  const closeOnReject = () => {
    setOpenDialog(false);
  };

  const { authInfo } = useAuthContext();
  return (
    <>
      <h3>Удаление аккаунта</h3>

      <hr />

      <div className={styles.Title}>
        <span>
          Вы можете удалить свой аккаунт, но помните: это действие невозможно
          отменить!
        </span>
      </div>

      <ProfileCenteredButton
        label={"Удалить аккаунт"}
        type={"submit"}
        onClick={async () => {
          handleClickOpen();
        }}
      />

      {openDialog ? (
        <ConfirmDeleteAccountDialog
          open={openDialog}
          closeOnApply={closeOnApply}
          closeOnReject={closeOnReject}
        />
      ) : (
        <></>
      )}
    </>
  );
}
