import { useState } from "react";
import { TLkAdminTableType, TLkTableType } from "../../types/LkTableType";
import {
  studyLkColumns,
  answerLkColumns,
} from "../../constants/columns/AnswerLkTeacherColumns";
import CustomTableMaterial from "../../shared/ui/table/customTableMaterial";
import { RowsPerPageEnum } from "../../constants/vars/RowsPerPageOptions";

function LkTable(props: {
  tableData: TLkTableType[] | TLkAdminTableType[];
  admin?: boolean;
}) {
  const [page] = useState(0);
  const [rowsPerPage] = useState(RowsPerPageEnum.ALL);

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  return (
    <div>
      {props.admin ? (
        <CustomTableMaterial<TLkAdminTableType>
          list={props.tableData as TLkAdminTableType[]}
          page={page}
          columns={studyLkColumns}
          localPagination={true}
          rowsPerPage={rowsPerPage}
          // handleChangeRowsPerPage={handleChangeRowsPerPage}
          // handleChangePage={handleChangePage}
        />
      ) : (
        <CustomTableMaterial<TLkTableType>
          list={props.tableData as TLkTableType[]}
          page={page}
          columns={answerLkColumns}
          rowsPerPage={rowsPerPage}
          // handleChangeRowsPerPage={handleChangeRowsPerPage}
          // handleChangePage={handleChangePage}
        />
      )}
    </div>
  );
}

export default LkTable;
