import styles from "./ourValues.module.scss";

export function OurValues() {
  return (
    <div className={styles.wrapper}>
      <div>
        <h2 className={styles.title}>Как проходит обучение?</h2>
        <ul>
          <li>
            <h3>Качество</h3>
            <p>
              Наши преподаватели и методисты - настоящие специалисты с
              многолетним опытом
            </p>
          </li>

          <li>
            <h3>Честность</h3>
            <p>
              Мы не готовим на 100 баллов за 3 недели. Пусть эти займутся
              "профессионалы".
            </p>
          </li>

          <li>
            <h3>Поддержка</h3>
            <p>
              Мы всегда на связи с учеником. В любое время можно задать вопрос в
              чате преподавателю или куратору
            </p>
          </li>

          <li>
            <h3>Эффективность</h3>
            <p>
              Онлайн-тренажер и большой банк задач позволяют составлять
              опитмальную программу подготовки
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}
