import { ReactNode } from "react";
import classNames from "classnames";
import styles from "./sticker.module.scss";

interface IStickerProps {
  className?: string;
  children: ReactNode;
}

export function Sticker(props: IStickerProps) {
  const { className, children } = props;

  return (
    <div className={classNames(className, styles.sticker)}>{children}</div>
  );
}
