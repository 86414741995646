import Date from "../../../../shared/ui/date/date";
import styles from "../../../Page.module.scss";
import React from "react";

export function IndividualLessonsPage() {
  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Title}>Индивидуальные занятия Minimath239</h1>

      <Date date={"2 сентября 2018"} />

      <p>Отвечаем на вопросы родителей про индивидуальные занятия.</p>

      <p>
        Да мы проводим индивидуальные занятия с детьми по методике minimath239
        для поступления в 5 класс лицея 239.
      </p>

      <p>
        Варианты обучения следующие:
        <br />
        а) Скайп уроки по алгоритмам решения заданий официальных тестов.
        Стоимость 750 руб/пол часа + проверка выполнения домашних заданий.
        <br />
        Ведет Фефелова Ольга. На этот сезон можем взять еще 1-2 человека.
      </p>

      <p>
        б) Уроки с приездом к вам домой. Стоимость 1000-1200 руб/час., в
        значимости от вашей удаленности от центра.
        <br />
        Ведут наши молодые коллеги –выпускники 239 и студенты мат факультетов
        университета (3 курс).
        <br />
        Можем взять 2-3 человека.
      </p>

      <p>
        в) Обучение на тренажере minimath239.
        <br />
        Бесплатно решение тестов на сайте. Платно работа в модуле Обучение.
        Запускается с сентября-октября.
      </p>

      <p>
        Содержание курса подготовки minimath239 высылается по запросу.{" "}
        <a href={"mailto:minimath239@yandex.ru"}>minimath239@yandex.ru</a>
      </p>

      <p>
        Надеемся, вы сможете подобрать удобный вариант.
        <br />
        Рекомендуем также записаться на подготовительные курсы в лицей 239 в
        феврале 2019.
      </p>
    </div>
  );
}
