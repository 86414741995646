import { editCreateColumnType } from "./EditCreateCourseColumns";

export const editCreateTaskColumns: editCreateColumnType[] = [
  { displayName: "ID", name: "id", type: "FormText", readonly: true },
  { displayName: "question_image", name: "questionImage", type: "FormImage" },
  { displayName: "question", name: "question", type: "FormTiny" },
  { displayName: "type_a", name: "answerType", type: "FormLookup" },
  { displayName: "mask_a", name: "answerMask", type: "FormText" },
  { displayName: "answer", name: "answer", type: "FormText" },
  { displayName: "answer_image", name: "answerImage", type: "FormImage" },
  { displayName: "version", name: "version", type: "FormTextArea" },
  { displayName: "solution", name: "solution", type: "FormTiny" },
  { displayName: "comment", name: "comment", type: "FormTiny" },
  // All below are not in the current production on minimath239.com
  {
    displayName: "rootId",
    name: "rootId",
    type: "FormText",
    formType: "number",
  },
  { displayName: "twin", name: "twin", type: "FormText", formType: "number" },
  {
    displayName: "parent_id",
    name: "parentId",
    type: "FormText",
    formType: "number",
  },
  {
    displayName: "theme_id",
    name: "themeId",
    type: "FormText",
    formType: "number",
  },
  { displayName: "order", name: "order", type: "FormText", formType: "number" },
  {
    displayName: "rating",
    name: "rating",
    type: "FormText",
    formType: "number",
  },
  { displayName: "year", name: "year", type: "FormText", readonly: true },
  { displayName: "var", name: "var", type: "FormText", readonly: true },
  {
    displayName: "tagList1",
    name: "tagList1",
    type: "FormText",
    readonly: true,
  },
  {
    displayName: "tagList2",
    name: "tagList2",
    type: "FormText",
    readonly: true,
  },
  {
    displayName: "tagList3",
    name: "tagList3",
    type: "FormText",
    readonly: true,
  },
];
