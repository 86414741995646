import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../shared/ui/button";

type TDialogProps = {
  open: boolean;
  closeOnApply: () => void;
  closeOnReject: () => void;
};

function ConfirmDeleteAccountDialog(props: TDialogProps) {
  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Подтвердите действие</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Вы уверены, что хотите удалить свой аккаунт?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid item sm={5}>
          <Button variant={"outlined"} onClick={props.closeOnReject} autoFocus>
            Нет
          </Button>
        </Grid>
        <Grid item sm={5}>
          <Button
            component={Link}
            to={"/"}
            variant={"outlined"}
            onClick={props.closeOnApply}
          >
            Да
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDeleteAccountDialog;
