export const AnswerTypeEnum = {
  TYPE_1: "тип 1",
  TYPE_2: "тип 2",
  TYPE_21: "тип 21",
  NO_SPACES: "nospaces",
  REG_EXP: "regexp",
  ALG: "alg",
  ALG2: "alg2",
  NULL: null,
};

export const AnswerEditTypeEnum = {
  TYPE_1: "тип 1",
  TYPE_21: "тип 21",
  NO_SPACES: "nospaces",
  REG_EXP: "regexp",
  ALG: "alg",
  ALG2: "alg2",
  NULL: null,
};

export enum CourseTypeEnum {
  EXAM = "EXAM",
  THEME = "THEME",
  ANY = "ANY",
}

export const AuthTypeEnum = {
  LOGIN: "login",
  REGISTER: "register",
};
