import { createContext, useContext, useEffect, useState } from "react";
import CommonService from "../APIService/CommonService";

export type TSiteNameContext = {
  siteNameList: any[];
};

const SiteNameContext = createContext<TSiteNameContext>({
  siteNameList: [],
});

function SiteNameProvider(props: { children: any }) {
  const [siteNameList, setSiteNameList] = useState([]);

  const siteNameListFetch = async () => {
    const response = await CommonService.getSitePages();
    setSiteNameList(response.data);
  };
  useEffect(() => {
    siteNameListFetch().then(() => console.log());
  }, []);
  return (
    <SiteNameContext.Provider value={{ siteNameList }}>
      {props.children}
    </SiteNameContext.Provider>
  );
}

export default SiteNameProvider;

export const useSiteNameContext = () => {
  return useContext(SiteNameContext);
};

export function setDocumentTitle(siteNameList: any[], page: string) {
  const x = siteNameList.find((x) => x.link === page);
  if (x) {
    document.title = x.title;
  }
}

export function getPageDecorations(siteNameList: any[], page: string) {
  const x = siteNameList.find((x) => x.link === page);
  if (x) {
    return x;
  }
  return undefined;
}
