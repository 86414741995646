import Date from "../../../../shared/ui/date/date";
import styles from "../../../Page.module.scss";
import React from "react";

export function BusinessOfferPage() {
  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Title}>
        «Бизнес предложение» для поступающих в 5-й класс ШГН.
      </h1>

      <Date date={"22 февраля 2020"} />

      <p>
        Уважаемые гости!
        <br />
        Предлагаю усилить мотивацию при подготовке к экзамену по математике.
      </p>

      <p>
        Каждому, кто первый сообщит об ошибке в ответе (только в тестах
        2009-2019), готов платить 500 рублей наличными на карту сбербанка. Или
        1000 рублей за интересную ошибку (на мое усмотрение). Сообщение об
        ошибке нужно разместить на форуме сайта{" "}
        <a href={"https://littleone.com/"}>www.littleone.ru</a> в теме{" "}
        <a href={"https://forum.littleone.ru/showthread.php?t=8164608"}>
          Поступаем в 5 класс 239, занимаемся на тренажере
        </a>
        . Обязательно указать код задачи и описание ошибки.
      </p>

      <p>
        Несколько ошибок по одной и той же задаче рассматриваю в порядке
        публикации. Если я признаю ваше сообщение ошибочным, то переходим к
        следующему. Если ошибка в ответе действительно имеет место быть, то я
        перевожу деньги и не рассматриваю следующие сообщения об ошибках по
        данной задаче.
      </p>

      <p>
        Сайт доступен для всех желающих бесплатно и я им занимаюсь в свободное
        от работы время. Поэтому мои ответы могут быть не быстрыми. В
        дискуссии вступать не планирую. Если считаю, что ваш ответ
        неправильный, то правильный ответ не сообщаю и не объясняю.
      </p>

      <p>Срок действия до 20.05.2020</p>

      <p>С уважением,</p>

      <p>Владимир Жидков</p>
    </div>
  );
}
