import FmlSecretsHeader from "../../../../components/articles/fmlSecrets/FmlSecretsHeader";
import FmlSecretsSecrets from "../../../../components/articles/fmlSecrets/FmlSecretsSecrets";
import FmlSecretsServices from "../../../../components/articles/fmlSecrets/FmlSecretsServices";
import FmlSecretsSummary from "../../../../components/articles/fmlSecrets/FmlSecretsSummary";
import styles from "../../../Page.module.scss";

export function FMLSecretsArticlePage() {
  return (
    <div className={styles.Wrapper}>
      <FmlSecretsHeader />
      <FmlSecretsSummary />
      <FmlSecretsSecrets />
      <FmlSecretsServices />
    </div>
  );
}
