import List from "../../../../shared/ui/list/list";
import Date from "../../../../shared/ui/date/date";
import styles from "../../../Page.module.scss";
import React from "react";

export function SummerLessonsPage() {
  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Title}>Занятия летом</h1>

      <Date date={"15 июня 2019"} />

      <p>
        Летом индивидуальные занятия с Minimath.ru не заканчиваются.
        <br />
        Кто и с какими целями занимается летом?
        <List
          data={[
            "Летом продолжают занимаются ученики третьего класса, которые готовятся к поступлению в пятый класс и им надо наверстать разрыв между стандартной программой начальной школы и требованиями ФМЛ 239 перед экзаменами  2020 года;",
            "Летом занимаются ребята, которые не поступили в 5 класс 239 (по разным причинам - не хватило времени подготовки, перенервничал на экзамене, не справился с уровнем требований и пр.), но решили в спокойном режиме пройти всю программу подготовки до конца. После чего, продолжить готовиться к поступлению уже в 8 класс;",
            "Летом занимаются ребята, родители которых решили поднять знаний своего ребенка до уровня требований  ФМЛ 239.",
          ]}
        />
      </p>

      <p>
        Занятия проходят с учетом каникул и переезда ребят на дачи, в теплые
        города и страны.
        <br />
        Формат занятий не изменился: скайп, интерактивная доска, домашние
        задания, ответы на вопросы 24/7.
      </p>

      <p>Если вам интересно заниматься летом, напишите или позвоните нам.</p>
    </div>
  );
}
