import { useState } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import styles from "./Form.module.scss";

function FormLookup(props: any) {
  const [value, setValue] = useState(props.value);
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
    props.onChange(event.target.value as string);
  };
  let x = 1;

  return (
    <div className={styles.Form} id={styles.indent}>
      <label className={styles.Label}>{props.title}</label>
      <div className={styles.InputHolder}>
        <Select value={value} style={{ width: 120 }} onChange={handleChange}>
          {Object.entries(props.enum as Object).map((t) => {
            return (
              <MenuItem key={(x++).toString()} value={t[0]}>
                {t[1]}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </div>
  );
}

export default FormLookup;
