import { TableCell } from "@mui/material";
import { MathJaxOutput } from "../../../components/mathJax/MathJaxOutput";
import { TTableColumn } from "../../../types/common/TableColumnType";

type TCustomTableCellProps = {
  column: TTableColumn;
  rowNumber: any;
  value: string | number | boolean;
  linkTo?: string;
};

function CustomTableCell(props: TCustomTableCellProps) {
  const { column, value, rowNumber, linkTo } = props;
  const stringValue = value?.toString() || "";
  return (
    <TableCell
      sx={{ "word-wrap": "anywhere" }}
      //key={column.id + rowNumber.id||rowNumber}
      align={column.align}
      id={rowNumber}
    >
      {
        <MathJaxOutput
          text={
            typeof linkTo !== "undefined" ? (
              <a href={linkTo! + value}>{stringValue}</a>
            ) : (
              stringValue
            )
          }
        />
      }
    </TableCell>
  );
}

export default CustomTableCell;
