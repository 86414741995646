import React, { useEffect, useRef, useState } from "react";
import CourseService from "../../../APIService/CourseService";
import CourseTitle from "../../../components/course/CourseHeader";
import { CourseLayer } from "../../../components/course/CourseLayer";
import { CourseName } from "../../../components/course/courseName";
import ExamHeader from "../../../components/course/ExamHeader";
import { CourseTypeEnum } from "../../../constants/enums";
import { useCourseContext } from "../../../context/courseContext";
import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import { Button } from "../../../shared/ui/button";
import Loading from "../../../shared/ui/loading/loading";
import { Scroller } from "../../../shared/ui/scroller";
import { Sticker } from "../../../shared/ui/sticker";
import { TCourseThemes } from "../../../types/CourseType";
import styles from "./coursesPage.module.scss";

export function CoursesPage(props: { courseType: CourseTypeEnum }) {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(
    siteNameList,
    props.courseType === CourseTypeEnum.EXAM ? "exam" : "theme",
  );

  const { courseType } = props;
  const ctx = useCourseContext();
  const { coursesList, setCoursesContext } = ctx;

  const listInnerRef = useRef<any>();
  useEffect(() => {
    const fetchCourses = async () => {
      const response = await CourseService.getThemesList({
        courseType: courseType!,
        take: 150,
        page: 0,
      });
      setCoursesContext(response.data);
      //     setWasLastList(response.data.length === 0);

      setIsCoursesLoading(false);
      //     setIsCoursesFirstLoading(false);
    };
    fetchCourses().then(() => console.log("rendered!"));
  }, [courseType, setCoursesContext]);

  // const [currPage, setCurrPage] = useState(0); // storing current page number
  // const [prevPage, setPrevPage] = useState(-1); // storing prev page number
  // const [wasLastList, setWasLastList] = useState(false); // setting a flag to know the last list

  const [isCoursesLoading, setIsCoursesLoading] = useState<boolean>(true);

  // const [isCoursesFirstLoading, setIsCoursesFirstLoading] =
  //   useState<boolean>(true);

  // useEffect(() => {
  //   if (!isCoursesFirstLoading) {
  //     setPrevPage(-1);
  //     setCurrPage(0);
  //   }
  //   setWasLastList(false);
  //   return () => {
  //     setIsCoursesFirstLoading(true);
  //     setCoursesContext([]);
  //   };
  // }, [props.courseType]);

  // useEffect(() => {
  //   if (!wasLastList && prevPage !== currPage) {
  // fetchCourses().then(() => console.log("rendered!"));
  //     setPrevPage(currPage);
  //   }
  // }, [isCoursesLoading, ctx.courseType, isCoursesFirstLoading]);

  return (
    <div
      ref={(el) => {
        // @ts-ignore
        listInnerRef.current = el;
      }}
      className={styles.Exams}
    >
      {courseType === CourseTypeEnum.THEME ? <CourseTitle /> : <ExamHeader />}
      {isCoursesLoading ? (
        <div className={styles.centeredContent}>
          <Loading />
        </div>
      ) : (
        <div className={styles.Wrapper}>
          <div id={"themesWrapper"} className={styles.ThemesWrapper}>
            {coursesList.map((x: TCourseThemes) => (
              <CourseName
                key={x.shortName || x.name}
                header={x.shortName || x.name}
              />
            ))}
          </div>

          <div className={styles.centeredArrowDown}>
            <img src={"/images/arrowdown.png"} alt={""} />
          </div>

          <div className={styles.ExamsCards}>
            {coursesList.length === 0 ? (
              <div>
                <p>Нет задач!</p>
              </div>
            ) : (
              coursesList.map((x: TCourseThemes) => (
                <CourseLayer
                  key={courseType + (x.shortName || x.name)}
                  courseType={courseType!}
                  header={x.shortName || x.name || ""}
                  courses={x.course}
                />
              ))
            )}
          </div>

          <Sticker className={styles.scrollButton}>
            <Scroller scrollToComponentName={"themesWrapper"}>
              <Button>Вернуться к перечню тем</Button>
            </Scroller>
          </Sticker>
        </div>
      )}
    </div>
  );
}
