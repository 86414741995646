import React from "react";
import styles from "./CourseHeader.module.scss";
import { useSiteNameContext } from "../../context/siteNameContext";

function CourseTitle() {
  const { siteNameList } = useSiteNameContext();
  const data = siteNameList.find((x) => x.name === "тема");

  return (
    <div className={styles.Header}>
      <h1 className={styles.Title}>{data?.header1}</h1>
    </div>
  );
}

export default CourseTitle;
