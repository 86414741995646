import React from "react";
import { Link } from "react-router-dom";
import { TCourse } from "../../types/CourseType";
import styles from "./CourseLayer.module.scss";

export interface ICourseLayerProps {
  courseType: string;
  header: string;
  courses: TCourse[];
}

export function CourseLayer(props: ICourseLayerProps) {
  let index = 1;
  return (
    <div id={props.header} className={styles.Card}>
      <h2 className={styles.Title}>{props.header}</h2>

      <div className={styles.Info}>
        {props.courses.map((data) => (
          <div key={data.id}>
            <p className={styles.Number}>
              {" "}
              {index > 9 ? index++ : "0" + index++}
            </p>

            <Link
              className={styles.Link}
              to={`/${props.courseType.toLowerCase()}/${data.id}`}
            >
              {data.siteName}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
