import styles from "./ExamHeader.module.scss";
import {
  getPageDecorations,
  setDocumentTitle,
  useSiteNameContext,
} from "../../context/siteNameContext";

function ExamHeader() {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "exam");
  const decorations = getPageDecorations(siteNameList, "exam");

  return (
    <div className={styles.Header}>
      <div className={styles.Wrapper}>
        <h1
          className={styles.Title}
          dangerouslySetInnerHTML={{ __html: decorations?.header1 }}
        ></h1>
      </div>

      <div className={styles.Wrapper}>
        <div className={styles.Grid}>
          <div className={styles.Text}>
            <p dangerouslySetInnerHTML={{ __html: decorations?.text1 || "" }} />
          </div>

          <img className={styles.Image} src={"/images/examimg.png"} alt={""} />
        </div>
      </div>
    </div>
  );
}

export default ExamHeader;
