import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import List from "../../../shared/ui/list/list";
import { FeedbackForm } from "../../../widgets/feedbackForm";
import styles from "./feedbackPage.module.scss";

export function FeedbackPage() {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "feedback");

  return (
    <>
      <h2 className={styles.Title}>Отзывы о нас</h2>

      <div className={styles.Wrapper}>
        <FeedbackForm
          fromWho={"Горбунова Н."}
          whyChose={
            <p>
              На самом деле случайно. Искал информацию результатам школ на
              Всеросе по математике. Наткнулся на статью "Почему Питер побеждает
              Московский школы". Заинтересовался. И в конце концов решил
              попробовать. Не пожалел.
            </p>
          }
          goals={
            <p>
              <ol>
                <li>
                  Минимизировать последствия обучения по программе "Школа
                  России"
                </li>

                <li>Минимум: поступить в лицей г. Лобня</li>

                <li>
                  Максимум: успешно сдать вступительные испытания в Физтех-лицей
                  (им. Капицы)
                </li>

                <li>
                  Суперцель: Хотя бы теоретически иметь навыки достаточные для
                  того, чтобы набрать 30 баллов в 239
                </li>
              </ol>
            </p>
          }
          importance={
            <p>
              Уважительное общение с учеником. Обратная связь. Наличие ДЗ.
              Возможность самостоятельного контроля выполнения ДЗ.
            </p>
          }
          liked={
            <p>
              Это не просто занятия с преподавателем, но полноценная система
              подготовки под конкретный результат. Занятия и Д\З дают всё, что
              требуется. Остальное - в руках ученика.
            </p>
          }
          disliked={
            <p>
              Я бы посмотрел в сторону расширения функционала отчётов. Я для
              себя делал следующее:
            </p>
          }
          wishes={
            <div>
              <p>
                Ставьте цель и регулярно идите к ней. Всё получится. Не сразу,
                но если цель стоит и каждый день делать шаги к её достижению, то
                результат обязательно будет.
              </p>

              <p>Успехов!</p>
            </div>
          }
          studyingPeriod={"сентябрь 2022 - май 2023"}
          date={"16 мая 2023"}
          reviewImageSrc={"images/reviews/review_gorbunov_20230516.jpg"}
        />

        <FeedbackForm
          fromWho={"Леоновой М."}
          whyChose={
            <p>
              Необходимость подготовки в вступительным экзаменам по математике
              при поступлении в ШГН.
            </p>
          }
          goals={
            <p>Поступление в физ мат школы СПб., приоритетная школа 239.</p>
          }
          goalsHandling={
            <p>Результат занятий - поступление в 239, 30-ку, 366 и ЮМШ.</p>
          }
          importance={
            <List
              data={[
                "Индивидуальные занятия в режиме онлайн",
                "Системный подход при обучении;",
                "Курс предусматривает изучение всех тем, знания которых требуется при написании вступительной работы;",
                "Педагогический талант преподавателя.",
              ]}
            />
          }
          liked={
            <List
              data={[
                "Очень большое количество различных задач по каждой отдельной теме;",
                "Большое разнообразие источников учебных заданий;",
                "Использование электронной доски во время обучения;",
                "Математический марафон незадолго до вступительных экзаменов.",
              ]}
            />
          }
          disliked={<p>При открытии варианта необх. кнопка "Печать".</p>}
          wishes={
            <p>
              Ученикам - набраться терпения и стараться практически ежедневно
              заниматься решением математических задач. Родителям - помочь
              ученикам организовать учебный процесс в рамках Minimath и
              контролирование его исполнение.
            </p>
          }
          studyingPeriod={"январь 2020 - май 2021"}
          date={"1 сентября 2021"}
          reviewImageSrc={"images/reviews/review_simanova_20210901.jpg"}
        />

        <FeedbackForm
          fromWho={"Кессель А."}
          whyChose={
            <p>
              Увидели этот сайт в интернете и решили попробовать. Не пожалели!.
            </p>
          }
          goals={
            <p>
              Хотели с помощью этих индивидуальных занятий поступить в 239
              лицей, 30 лицей и ЮМШ. Цель оправдалась. Поступили во все три
              школы.
            </p>
          }
          goalsHandling={<p>Отвечал на 100%.</p>}
          importance={
            <p>Чтобы ребенку четко и понятно был донесен материал.</p>
          }
          liked={
            <p>
              Знания, которые получил ребенок и чуткое отношение педагога
              Фефеловой О.М.
            </p>
          }
          disliked={<p>Такого нет</p>}
          wishes={
            <p>
              Верить в себя и своих детей. Не "давить" на них. А также понимать,
              что конкуренция из года в год растёт и дети делают всё, что от них
              зависит
            </p>
          }
          studyingPeriod={"апрель 2020 - май 2021"}
          date={"1 сентября 2021"}
          reviewImageSrc={"images/reviews/review_kessel_20210901.jpeg"}
        />

        <FeedbackForm
          fromWho={"Артюховой Д."}
          whyChose={
            <p>Потому что он нацелен именно на подготовку в ФМЛ 239.</p>
          }
          goals={<p>Поступление в ФМЛ 239 или 30.</p>}
          goalsHandling={
            <p>
              Полностью отвечал. Ребёнок поступил в 239 с результатом в 1 туре
              45 баллов при проходном 33. Также уверенно поступил в 30ку, ЮМШ,
              ФМЛ 366 и 610 гимназию.
            </p>
          }
          importance={
            <p>
              Во-первых, что обучение онлайн, т.к. мы находились в другом
              регионе. Во-вторых, было важно, чтобы преподаватель
              специализировался именно на подготовке к 239.
            </p>
          }
          liked={
            <p>
              Индивидуальный подход, моральная поддержка и, естественно, сами
              уроки: и процесс, и резултат, атмосфера на уроке, объяснение
              материала.
            </p>
          }
          disliked={<p>Такого нет :)</p>}
          wishes={
            <p>
              Курс Minimath идеален для подготовки к серьезным школам. Но очень
              важно понимать, что это не волшебная пилюля, а результат большого
              совместного труда: преподавателя, ученика и родителей.
              Преподаватель даёт инструмент, знает как преподать материал и как
              его отработать, но без желания и трудолюбия самого ученика
              "впихнуть" знания невозможно. Также важно, чтобы родители следили
              за добросовестностью исполнения Д/З, создавали правильное
              отношение к знаниям, следили за информационным окружением и
              образом жизни ребёнка. Если ваш ребёнок сидит на играх и гаджетах,
              то ждать какого-то успеха не стоит и преподаватель лишь зря будет
              растрачивать свой талант и время. Но если вы создаёте ребенку
              благоприятную для учебы атмосферу и настрой, и ребёнок готов
              вбирать знания и трудиться, то уроки в Minimath непременно дадут
              великолепные плоды.
            </p>
          }
          studyingPeriod={"октябрь 2018 - август 2020"}
          date={"28 сентября 2020"}
          reviewImageSrc={"images/reviews/review_artuhova_20210901.jpg"}
        />

        <FeedbackForm
          fromWho={"Грошовой Е."}
          whyChose={<p>Рекомендация.</p>}
          goals={<p>Поступление лицей 30 и возможно 239.</p>}
          goalsHandling={<p>100%. Цель достигнута – поступили в 30 лицей.</p>}
          importance={
            <p>Профессиональный педагог и возможность занятий он-лайн.</p>
          }
          liked={
            <p>
              Индивидуальный подход, моральная поддержка и, естественно, сами
              уроки: и процесс, и резултат, атмосфера на уроке, объяснение
              материала.
            </p>
          }
          disliked={<p>Чуть больше обратной связи для родителей.</p>}
          wishes={<p>Успехов и новых побед!</p>}
          studyingPeriod={"март 2020 - август 2020"}
          date={"1 сентября 2020"}
          reviewImageSrc={"images/reviews/review_groshova_20200901.jpg"}
        />

        <FeedbackForm
          fromWho={"Ковязиной С."}
          whyChose={
            <p>
              Искали курсы по олимпиадной математике, нашли Minimath и решили
              попробовать, ни разу не пожалели.
            </p>
          }
          goals={<p>Поступление в ФМЛ 239, 30.</p>}
          goalsHandling={<p>Отвечал полностью.</p>}
          importance={
            <p>
              Получить недостающие знания, научиться решать нестандартные,
              олимпиадные задачи, индивидуальный подход, взаимопонимание
              ученик-учитель.
            </p>
          }
          liked={
            <p>
              Онлайн обучение в удобное для вас время. Д/З и его проработка, все
              занятия с объяснениями остались у нас.
            </p>
          }
          disliked={<p>Всё понравилось.</p>}
          wishes={
            <p>
              Пришли на курс «зелёными», из обычной дворовой школы, никогда не
              участвовали в мат. Олимпиадах. За год обучения получили
              колоссальный объём знаний. 29 баллов из 33 (проходной) – для нас
              это победа! Верьте в своих детей и всё получится.
            </p>
          }
          studyingPeriod={"май 2019 - август 2020"}
          date={"10 сентября 2020"}
          reviewImageSrc={"images/reviews/review_kovyazina_20200910.jpg"}
        />

        <FeedbackForm
          fromWho={"Шапкиной А."}
          whyChose={
            <p>
              Ребенку были необходимы индивидуальные качественные,
              предпочтительно удаленные занятия для подготовки к поступлению в
              мат лицей.
            </p>
          }
          goals={<p>Планомерно и основательно подготовиться к поступлению.</p>}
          goalsHandling={
            <p>Цель полностью достигнута. Ребенок поступил в лицей 239.</p>
          }
          importance={
            <p>
              Системность обучения, понятные конспекты по пройденным темам,
              гибкий график, индивидуальный подход, возможность качественного
              дистанционного взаимодействия с преподавателем.
            </p>
          }
          liked={
            <p>
              Индивидуальный подход, моральная поддержка и, естественно, сами
              уроки: и процесс, и резултат, атмосфера на уроке, объяснение
              материала.
            </p>
          }
          disliked={<p>-</p>}
          wishes={
            <p>Регулярно заниматься и успешно поступить в целевую школу.</p>
          }
          studyingPeriod={"январь 2019 - август 2020"}
          date={"22 сентября 2020"}
          reviewImageSrc={"images/reviews/review_shapkina_20200908.jpg"}
        />

        <FeedbackForm
          fromWho={"Слепневой О."}
          whyChose={
            <p>
              Изначально как платформу для отработки задач, позднее обратились
              за индивидуальными занятиями.
            </p>
          }
          goals={
            <p>
              Ставили целью поступление в лицей 239. В процессе подготовки стало
              понятно, что ребенок не настолько заинтересован математикой.
              Изменили на развитие логики и углубленное изучение предмета.
            </p>
          }
          goalsHandling={<p>Полностью отвечал.</p>}
          importance={
            <p>
              Было важно, что ребенок самостоятельно выполняет домашние задания,
              контакт с Ольгой Михайловной был прекрасным. Все сложные моменты
              она объяснила очень доходчиво.
            </p>
          }
          liked={
            <p>
              Преподаватель Ольга Михайловна. Один из лучших педагогов, с
              которыми удалось познакомиться и позаниматься.
            </p>
          }
          disliked={
            <p>
              Нет такого. Занятия построены очень четко, прекрасно спланированы.
            </p>
          }
          wishes={
            <p>
              Желаю ставить высокие цели, трудиться, расти над собой и получать
              удовольствие от занятий.
            </p>
          }
          studyingPeriod={"октябрь 2019 - июль 2020"}
          date={"25 августа 2020"}
          reviewImageSrc={"images/reviews/review_slepneva_20200922.jpg"}
        />

        <FeedbackForm
          fromWho={"Бойцовой М."}
          whyChose={
            <p>Для подготовки ребенка к поступлению в мат класс фмл 366.</p>
          }
          goals={<p>Поступить в мат класс фмл 366.</p>}
          goalsHandling={<p>Отвечал на 100%</p>}
          importance={
            <p>
              Обсуждение задач, которые могли встретиться на экзамене,
              объяснение трудных моментов
            </p>
          }
          liked={<p>Терпение учителя. Егор сказал: "Отличный учитель".</p>}
          disliked={<p>Нет. Только более часа подряд в группе было сложно</p>}
          wishes={
            <p>
              Поступить в тот лицей, в который хотят поступить. Не сдаваться,
              решать, обсуждать!
            </p>
          }
          studyingPeriod={"июнь - август 2020"}
          date={"27 августа 2020"}
          reviewImageSrc={"images/reviews/review_boytsova_20200827.jpg"}
        />

        <FeedbackForm
          fromWho={"Мустакаевой Е."}
          whyChose={
            <p>Программа курса полностью соответствует цели обучения.</p>
          }
          goals={<p>Подготовка к вступительной олимпиаде ПФМЛ239.</p>}
          goalsHandling={<p>Полностью.</p>}
          importance={
            <p>
              Удобный режим по скайпу и сохранение истории занятий в облаке
              (On-line доска).
            </p>
          }
          liked={<p>On-line доска с конспектом занятий. Прекрасный педагог.</p>}
          disliked={<p>-</p>}
          wishes={
            <p>
              Полезно просить ребенка рассказывать что проходили по конспекту
              для закрепления материала.
            </p>
          }
          studyingPeriod={"март - май 2019"}
          date={"12 сентября 2019"}
          reviewImageSrc={"images/reviews/review_mustakaeva_20190912.png"}
        />

        <FeedbackForm
          fromWho={"Фроловой Н."}
          whyChose={
            <List
              data={[
                "Индивидуальный подход",
                "Наличие узкой специализации – подготовка к поступлению в 239.",
              ]}
            />
          }
          goals={<p>Поступление в ШГН (№239, №30)</p>}
          goalsHandling={<p>На 100%</p>}
          importance={
            <List
              data={[
                "Проработка слабых мест в знаниях;",
                "Освоение четких алгоритмов решения задач;",
                "Гибкий график занятий в режиме он-лайн (скайп).",
              ]}
            />
          }
          liked={
            <List
              data={[
                "Поддержка и мотивация со стороны преподавателя;",
                "Наличие чёткого плана подготовки;",
                "Контроль и помощь в выполнении ДЗ;",
                "Оперативная обратная связь.",
              ]}
            />
          }
          disliked={<p>Всё понравилось!!!</p>}
          wishes={
            <>
              <p>Детям – трудолюбия, терпения, удачи!!!</p>
              <p>Родителям – веры, поддержки и безграничной любви!!!</p>
            </>
          }
          studyingPeriod={"2018 - 2019"}
          date={"31 августа 2019"}
          reviewImageSrc={"images/reviews/review_frolova_20190831.png"}
        />

        <FeedbackForm
          fromWho={"Шпадарук Н."}
          whyChose={
            <List
              data={[
                "Искали курсы подготовки именно в 239 лицей;",
                "Очень удобная форма занятий, не теряли время на дорогу.",
              ]}
            />
          }
          goals={<p>Поступление в 239 лицей.</p>}
          goalsHandling={
            <p>
              Абсолютно отвечал, т.к. данный курс нацелен именно на подготовку в
              239, а не просто на расширение школьной программы по математике
            </p>
          }
          importance={
            <p>
              Чтобы ребенок перестал бояться новых нестандартных задач; снять
              зажим «таких задач в школе мы не решали»; развить умение мыслить и
              рассуждать самостоятельно.
            </p>
          }
          liked={
            <List
              data={[
                "Индивидуальный подход в самом широком смысле; наличие четкой методики;",
                "Наличие большого количества материала для подготовки именно в 239;",
                "Высокая интенсивность занятий, полное погружение.",
              ]}
            />
          }
          disliked={<p>Нам всё понравилось.</p>}
          wishes={
            <>
              <p>
                От Миши: главное – это терпение, желание и больше заниматься.
                Удачи!!!
              </p>

              <p>
                От мамы: поддерживайте своих детей, верьте в них и всё
                получится.
              </p>
            </>
          }
          studyingPeriod={"август 2018 - май 2019"}
          date={"29 августа 2019"}
          reviewImageSrc={"images/reviews/review_shpanduk_20190829.jpg"}
        />
      </div>
    </>
  );
}
