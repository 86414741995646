import FeatureOfMinimathHeader from "../../../../components/articles/featuresOfMinimath/FeatureOfMinimathHeader";
import styles from "../../../Page.module.scss";

export function FeaturesOfMinimathArticlePage() {
  return (
    <div className={styles.Wrapper}>
      <FeatureOfMinimathHeader />
    </div>
  )
}
