import { Grid } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Answer25Table from "../../../components/lk/Answer25Table";
import AuthInput from "../../../components/login/AuthInput";
import { useAuthContext } from "../../../context/authContext";
import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import { Button } from "../../../shared/ui/button";
import styles from "./answer25Page.module.scss";
import { useLoadingHook } from "../../../hooks/common/Hooks";
import { UserService } from "../../../APIService/UserService";
import { TUserInfo } from "../../../types/UserInfoType";
import AdminService from "../../../APIService/AdminService";
import { TAnswer25Type } from "../../../types/TAnswer25Type";

export function Answer25Page() {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "answer25");

  const { authInfo } = useAuthContext();
  const params = useParams();
  const id = params.id ? Number(params.id) : authInfo.id;
  const [days, setDays] = useState<number>(30);
  const [daysToShow, setDaysToShow] = useState<number>(30);
  const [error, setError] = useState<boolean>(false);
  const handleChange = (event: any) => {
    const { value } = event.target;
    setDaysToShow(value);
    setError(value > 30 || value < 0);
  };

  const handleClick = () => {
    setDays(daysToShow);
  };

  const userDataFetch = useCallback(async () => {
    const response = await UserService.getUserInfo(id);
    return response.data as TUserInfo;
  }, [id]);

  const reportParam = useMemo(() => {
    return { id, days };
  }, [id, days]);

  const tableDataFetch = useCallback(async () => {
    const result = await AdminService.getAnswer25Info(
      reportParam.id,
      reportParam.days,
    );
    return result.data as TAnswer25Type[];
  }, [reportParam]);

  const { pending, result } = useLoadingHook(userDataFetch, id);

  const { pending: tableDataPending, result: tableDataResult } = useLoadingHook(
    tableDataFetch,
    reportParam,
  );

  return (
    <div className={styles.Wrapper}>
      <h3 className={styles.Title}>
        Отчет 25. Задачи назначенные ученику {id} ({result?.nickname},{" "}
        {result?.email}) и его ответы
      </h3>

      <Grid container>
        <Grid item xs={12} sm={4} md={2}>
          <AuthInput
            size="small"
            label={"Дни"}
            value={daysToShow}
            onChange={handleChange}
          />
          {error ? (
            <p className={styles.ErrorMsg}>
              {"Введите число, меньшее 30 и большее 0"}
            </p>
          ) : (
            <p></p>
          )}
        </Grid>

        <Grid xs={12} sm={4} md={3}>
          <Button
            loading={pending || tableDataPending}
            disabled={error}
            styleClass={styles.RowInput}
            onClick={handleClick}
          >
            Обновить отчет
          </Button>
        </Grid>
      </Grid>
      <p />
      <div>
        {tableDataResult?.length ? (
          <Answer25Table tableData={tableDataResult} />
        ) : (
          <p>
            Ой, кажется, нет статистики за указанный период! Попробуйте изменить
            период!{" "}
          </p>
        )}
      </div>
    </div>
  );
}
