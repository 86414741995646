import styles from "../../Page.module.scss";

export function PrivacyPage() {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Article}>
        <h2>Политика конфиденциальности</h2>
        <p>
          Настоящая Политика конфиденциальности действует в отношении персональных
          данных пользователей, получаемых Администраторам Сайта Minimath239 в
          процессе работы Пользователя с Сайтом/ Использование Сайта означает
          безоговорочное согласие Пользователя с настоящей Политикой
          конфиденциальности и указанными в ней условиями обработки его
          персональных данных; в случае несогласия с этими условиями Пользователь
          должен воздержаться от использования Сайта.
        </p>
        <p>
          Перед использованием Сайта Пользователю необходимо внимательно изучить
          настоящую Политику конфиденциальности.
        </p>

        <ol>
          <li>ПЕРСОНАЛЬНЫЕ ДАННЫЕ</li>
          <ol>
            <li>
              Предоставление в любой форме (регистрация на Сайте, осуществление
              заказов, подписка на рекламные рассылки и тд.) своих персональных
              данных Администраторам сайта, Пользователь выражает согласие на
              обработку своих персональных данных в соответствии с Федеральным
              законом “О персональных данных” от 27.07.2006 №152-ФЗ.
            </li>

            <li>
              Обработка персональных данных производится исключительно на
              территории Российской Федерации, с соблюдением действующего
              законодательства Российской Федерации.
            </li>

            <li>
              В случае отзыва согласия на обработку персональных данных
              Пользователя, Пользователь уведомляет об этом Администраторов Сайта
              письменно или по электронной почте. После получения данного
              уведомления Администратор Сайта прекращает обработку персональных
              данных Пользователя и удаляет.
            </li>

            <li>
              Сайт не имеет статуса оператора персональных данных. Персональные
              данные Пользователя не передаются каким-либо третьим лицам, за
              исключением случаев, прямо предусмотренных настоящей Политикой
              конфиденциальности.
            </li>
          </ol>

          <li>МЕРЫ ПО ЗАЩИТЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</li>

          <ol>
            <li>
              В своей деятельности Администраторы сайта руководствуется
              Федеральным законом “О персональных данных” от 27.07.2006 №152-ФЗ.
            </li>

            <li>
              Администраторы сайта принимает все разумные меры по защите
              персональных данных Пользователей и соблюдает права субъектов
              персональных данных, установленные действующим законодательством
              Российской Федерации.
            </li>

            <li>
              Защита персональных данных Пользователя осуществляется с
              использованием физических, технических и административных
              мероприятий, нацеленных на предотвращение риска потери,
              неправильного использования, несанкционированного доступа, нарушения
              конфиденциальности и изменения данных. Меры обеспечения безопасности
              включают в себя межсетевую защиту и шифрование данных, контроль
              физического доступа к центрам обработки данных, а также контроль
              полномочий на доступ к данным.
            </li>
          </ol>

          <li>ИЗМЕНЕНИЕ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</li>

          <ol>
            <li>
              Администраторы сайта оставляет за собой право в одностороннем
              порядке вносить любые изменения в Политику конфиденциальности без
              предварительного уведомления Пользователя. Актуальный текст Политики
              конфиденциальности размещен на данной странице.
            </li>
          </ol>
        </ol>
      </div>
    </div>
  );
}
