import React from "react";
import Quote from "../../../shared/ui/quote/quote";

function FmlSecretsSecrets() {
  return (
    <>
      <p>
        Возвращаясь к «секретам 239» хочу поделиться своими гипотезами. Порядок
        следования "секретов" не равен важности. Важна именно их совокупность.
      </p>
      <h2>Стабильный, сильный и независимый директор</h2>
      <p>
        Максим Яковлевич Пратусевич, сам выпускник ФМЛ 239, работает в лицее с
        1992 года и руководит школой в течение последних 12 лет. У него
        получается разумно совмещать общение с государственными органами и
        управление школой.
      </p>
      <Quote
        hrefToSource="https://www.sobaka.ru/city/city/101039"
        author="Виктория Пятыгина"
        date="17 декабря 2019"
      >
        <p>
          Вопрос: Этим летом вы были в числе трех претендентов на кресло
          сенатора от Петербурга. Вы бы хотели сделать политическую карьеру?
          Ответ:
        </p>

        <p>
          Нет. Одна из причин, почему я работаю учителем, в том, что здесь
          существует чистота отношений — в отличие от бизнеса и политики. Здесь
          булыжников за пазухой существенно меньше, равно как и фиг в кармане —
          когда ко мне приходит человек, я могу себе позволить априори ему
          доверять, считать его порядочным.
        </p>
      </Quote>
      <h2>
        Постоянный, качественный преподавательский состав, который не
        "перебегает" из школы в школу
      </h2>
      <p>
        Общая атмосфера в школе позволяет привлекать самостоятельных и
        независимых учителей. Предметы не делятся на важные и не важные. Каждый
        учитель вправе поставить столько единиц, сколько посчитает нужным, и
        никто не будет вызывать его "на ковер". Популярна фраза "Двойку надо
        заслужить".
      </p>
      <p>
        В 239 работает тренер национальной сборной россии по математике Сухов
        Кирилл Андреевич. Выпускник ФМЛ239, преподаватель ФМЛ с 2015 года
      </p>
      <h2>Исключительно сильное дополнительное образование</h2>
      <p>
        Ключевой вклад в подготовку олимпиадников вносит Математический центр,
        бесконечное количество лет возглавляемый Рукшиным Сергеем Евгеньевичем.
        В МЦ по отдельному конкурсу может поступить любой ученик города. В 5-м
        классе занимается условно 100 человек, к 8-му классу формируется группа
        лидеров из 30 человек, большинство из которых поступают в 8 литера "1"
        класс ФМЛ 239. Именно "единички", как правило, выигрывают олимпиады.
      </p>
      <p>Результаты всероссийской олимпиады 2021 по классам лицея:</p>
      <img src="/images/winners-class-litera.png" alt={""} />
      <p>
        Замечание: "Класс" по данным итогов олимпиады, "Класс-Литера" по данным
        с сайта ФМЛ 239.
      </p>
      <p>
        Непосредственно школьное обучение математике ориентируется на
        формализованные, проверенные временем, требования к уровню знаний.
        Базовые требования по математике к классам с литерами 2-8 заметно
        слабее. У преподавателей математиков нет возможности забирать у учеников
        время, которое им (ученикам) нужно для подготовки к другим предметам.
        Хотя и тут многое зависит от преподавателя. Класс 11-2, который вел
        Сухов Кирилл, сдал математику лучше, чем 11-1.
      </p>
      <p>Таблица: Объем преподавания математики в неделю.</p>
      <img src="/images/scope-of-teaching-mathematics.png" alt={""} />
      <p> Источники:</p>
      <a href="https://drive.google.com/uc?export=download&id=1grl5Px-GlpGxMkkNhbyPCFSus2eRy5O8">
        Учебный план 239
      </a>{" "}
      и{" "}
      <a href="https://drive.google.com/uc?export=download&id=12Z2DGg6pzyM4bN1ilFv_xkM_ea12h8Hh">
        Расписание кружков на 2020-2021 уч. год
      </a>
      <p>
        Примеры задач вступительных экзаменов в Санкт-Петербургский
        Математический Центр при ФМЛ 239 для учеников 5 класса:
      </p>
      <a href="hhttps://minimath239.com/exam/366">XXVIII открытая олимпиада</a>{" "}
      (октябрь 2020),{" "}
      <a href="https://minimath239.com/exam/389">XXIX открытая олимпиада</a>{" "}
      (октябрь 2021)
      <h2>Открытость и стабильность в требованиях к поступлению</h2>
      <p>
        На мой взгляд, в 239 практически невозможно поступить за деньги
        (взятку). Т.е. в классах нет балласта из заведомо слабых детей. На сайте
        лицея выложены все вступительные работы, начиная с 1997 года. Для 5-го
        класса с 2009 года. Через день после экзамена 2020 года, Сухов Кирилл
        выложил на сайте лицея видео с объяснением всех задач для 5-го класса с
        критериями оценки.
      </p>
      <p>
        Сложность вступительных работ увеличивается, но границы тем, которые
        нужно знать, на наш взгляд, остаются прежними. Для поступления в 5-й
        класс ребенок должен изучить около 120 тем.
      </p>
      <p>
        Стабильность требований позволила нам (сайту minimath239) и многим нашим
        коллегам-конкурентам серьезно вложиться в методологию подготовки
        специально «под ФМЛ 239». Мы создали платформу из 3500 задач и методику
        автоматического формирования новых домашних заданий в расчете на то, что
        спрос на качественную подготовку к поступлению в 239 будет только
        увеличиваться. В СПб с 2010 года работает частная специализированная
        школа Квадривиум (1-4 классы), которая целенаправленно готовит детей к
        поступлению в 5-й класс школ городского набора. В таблице указано
        количество поступивших в 5 класс 239. Для 2021 года указан наш
        оптимистичный прогноз.
      </p>
      <img src="/images/quadriviumvsminimath.png" alt={""} />
      <p>
        P.S. В какой мере мы помогаем ФМЛ 239 готовить таланты, а в какой
        "натаскиваем" "обычных" детей и усложняем 239 отбор перспективных
        учеников, я планирую оценить в другой статье.
      </p>
      <p>
        P.S. В 2021 году 4 выпускника Minimath239 успешно сдали экзамен по
        математике в 5 класс. По результатам 2-х туров в ФМЛ 239 зачислены 2
        ученика.
      </p>
      <p>
        Задачи вступительных экзаменов в 5 класс ФМЛ 239 в 2021 году:{" "}
        <a href="https://minimath239.com/exam/351">1 вариант</a>{" "}
        <a href="https://minimath239.com/exam/352">2 вариант</a>
      </p>
      <h2>Сложившаяся иерархия школ города, которую признают все родители</h2>
      <p>
        Почти все сильные "математические" дети города собраны в одной школе.
        Приблизительный уровень требований к вступительным экзаменам по
        математик в 5 классы некоторых школ можно оценить из таблицы с
        результатами экзаменов наших учеников.
      </p>
      <img src="/images/2019-year.png" alt={""} />
      <h2>Территория влияния (возможность отбора талантов)</h2>
      <p>
        Для примера возьмем количество первоклассников в 2015 году. В России 1.6
        млн, в Москве 100 тысяч, в Петербурге 47 тысяч.
      </p>
      <p>
        В 2019 году в 5-й класс лицея 239 поступили 60 лучших первоклассников
        2015 года (норма 50, но по факту берут 60, с учетом на отсев). В 2022
        году, при поступлении в 8 и 9 класс из этих же 47 тысяч отберут еще по
        50 лучших. При поступлении в 10 класс добавится 30 человек из СПб и
        30-40 человек со всей России, проживающих в интернате лицея (всего в
        интернате 80 мест).
      </p>
      <p>
        Таким образом, по моим оценкам, ФМЛ 239 имеет возможность каждый год
        отбирать таланты из 50 тысяч учеников Санкт-Петербурга и 200 тысяч
        учеников России.
      </p>
      <p>
        В Москве отсутствует явный лидер, поэтому "обычная" лучшая школа Москвы,
        за исключением СУНЦ МГУ и других интернатов при именитых ВУЗах, имеет
        возможность отбирать таланты только из 20-30 тысяч учеников Москвы.
      </p>
      <p>
        В борьбе за общероссийские таланты среди учеников 10 и 11 класса ФМЛ
        239, по моему мнению, проигрывает Москве. В Москве есть СУНЦ МГУ -
        "Специализированный учебно-научный центр — школа-интернат имени А.Н.
        Колмогорова при Московском Государственном Университете". Рейтинг МГУ и
        хорошая авиационная логистика почти не дают возможности конкуренции для
        ФМЛ 239.
      </p>
      <p>
        Особняком стоит частная подмосковная школа-пансионат Летово, которая
        целенаправленно собирает со всей России победителей серьезных олимпиад
        на бесплатное обучение (прием в 7-10 классы). В 2021 году во
        всероссийской олимпиаде по математике приняли участие 10 учеников из
        Летово.
      </p>
    </>
  );
}

export default FmlSecretsSecrets;
