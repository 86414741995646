import React from "react";
import { OurTeamContainer } from "../../../widgets/ourTeamContainer";
import styles from "./ourTeamPage.module.scss";

export function OurTeamPage() {
  document.title = "Наша Команда";

  return (
    <div className={styles.Wrapper}>
      <div className={styles.TitleHolder}>
        <h1 className={styles.Title}>Наша команда</h1>

        <p className={styles.Title}>
          Мы выпускники математических факультетов, выпускники 239. Имея большой
          опыт подготовки и сдачи экзаменов в ФМЛ №239, решили перенести его в
          нашу программу и поделиться им с другими.
        </p>
      </div>

      <OurTeamContainer />

      <div className={styles.TitleHolder}>
        <p className={styles.Title}>
          Нам важно разделить с вами наши ценности - любовь к математике,
          простоту и функциональность в подходе, экономию и ценность времени,
          нацеленность на результат и, конечно, любовь к детям.
        </p>
      </div>
    </div>
  );
}
