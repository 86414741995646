import Date from "../../../../shared/ui/date/date";
import styles from "../../../Page.module.scss";
import React from "react";

export function CongratulationsAntonPage() {
  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Title}>
        Поздравляем нашего ученика Антона А. за победу в олимпиаде ЮМШ
      </h1>

      <Date date={"31 декабря 2019"} />

      <p>
        Искренне поздравляем Антона и всю его семью за отличные успехи - 1 место
        в олимпиаде среди 4 классов. Так держать! Спасибо!
      </p>

      <img src={"/images/achievements/congratualtions_anton_a.png"} alt={""} />
    </div>
  );
}
