import { $host } from "./index";
import { TUserInfo } from "../types/UserInfoType";

export class UserService {
  static async deleteUser(id: number) {
    return await $host.delete("user/" + id);
  }

  static async getUserInfo(id: number) {
    return await $host.get("user/" + id);
  }

  static async updateUserInfo(body: TUserInfo) {
    return await $host.put("user/" + body.id, body);
  }

  static async updatePassword(id: number, body: { newPassword: string }) {
    return await $host.put("user/update-password/" + id, body);
  }
}
