import Date from "../../../../shared/ui/date/date";
import styles from "../../../Page.module.scss";
import React from "react";

export function StudentsCongratulationsPage() {
  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Title}>Поздравляем наших учеников</h1>

      <Date date={"10 июня 2019"} />

      <p>
        Поздравляем всех наших учеников и их родителей с окончанием
        вступительных испытаний 2019!
        <br />
        Спасибо за ваше упорство в подготовке и храбрость во время экзамена.
        <br />
        Пусть на новом месте учебы вас ждет много интересного, прекрасные
        учителя и отличные одноклассники.
        <br />
        Всем успехов! Спасибо, что были с minimath.ru
      </p>

      <p>
        Особые поздравления Михаилу Ш. поступившему одновременно во все лицеи:
        фмл 239, 30-ка и 644. Браво!
      </p>
    </div>
  );
}
