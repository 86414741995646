import React from "react";

function FmlSecretsServices() {
  return (
    <>
      <h2>Итоги всероссийской олимпиады по математике 2021 года</h2>
      <p>
        Уроки с преподавателями:
        <a href="https://www.minimath.ru/feedback">Отзывы</a>.
      </p>
      <p>
        Результаты работы за год. Обращение{" "}
        <a href="https://minimath239.com/fefelova">Фефеловой Ольги</a>.{" "}
      </p>
      <p>
        Новая версия онлайн тренажера:{" "}
        <a href="https://minimath239.com/">www.minimath239.com</a>
      </p>
    </>
  );
}

export default FmlSecretsServices;
