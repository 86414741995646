import React from "react";
import Header from "../../../../shared/ui/header/header";
import List from "../../../../shared/ui/list/list";
import styles from "../../../Page.module.scss";

export function SolvingProblemArrivedPage() {
  return (
    <div className={styles.Wrapper}>
      <Header
        title={"Решение задачи про приехавших раньше и опаздывающих"}
        date={"27 апреля 2022"}
        imageSrc="/images/articles/article-header-test.png"
      >
        <p>
          Задача: Велосипедист должен попасть в место назначения к определенному
          сроку. Известно, что если он поедет со скоростью 15 км/ч, то приедет
          на 1 час раньше, а если со скоростью 10 км/ч, то опоздает на 1 час. С
          какой скоростью он должен ехать?
        </p>
        <p> Ответ: 12 км/ч</p>
      </Header>

      <h2>Решение 1. Весы, принцип уравнивания.</h2>

      <List
        data={[
          <>
            <p> Пусть у нас два велосипедиста:</p>
            <p>
              a) один быстрый, едет со скоростью 15 км/ч и проезжает все
              расстояние за t часов,
            </p>
            <p>
              b) второй медленный, едет со скоростью 10 км/ч и проезжает
              расстояние за t+2 часа. Разница во времени медленного и быстрого
              равна 2-м часам, так как быстрый приезжает на час раньше, а
              медленный на час позже запланированного времени.
            </p>
          </>,

          <p>
            Оба проезжают одинаковое расстояние, что позволяет их уравнять
            (получаем уравнение). Чтобы решить уравнение его надо максимально
            упростить. Для этого можем убрать из рассмотрения одинаковые части в
            пройденных расстояниях. Что одинаковое у медленного и быстрого? -
            Расстояние, которое проехал быстрый со скоростью медленного 10
            км/ч*t и расстояние, которое проехал медленный за время быстрого 10
            км/ч*t Остается рассмотреть оставшиеся части пути у быстрого и у
            медленного.
          </p>,

          <p>
            Если быстрый поедет со скоростью медленного, то за каждый час его
            пройденный путь будет на 5 км меньше (не 15км, а 10км, не 30 км, а
            20 км и т.д.), сколько часов движется, столько раз будет на 5 км
            меньше. В итоге через время t ему останется еще проехать 5 км/ч * t
            <img
              src={"/images/articles/solvingProblemArrive/figure_1.jpg"}
              alt=""
            />
          </p>,

          <p>
            Если медленный едет столько времени, сколько быстрый, то через время
            t (время быстрого) ему останется ехать по времени еще 2 часа
            (разница времени быстрого и медленного) расстояние 2 ч*10 км/ч =20км
            <img
              src={"/images/articles/solvingProblemArrive/figure_2.jpg"}
              alt=""
            />
          </p>,

          <p>
            Время быстрого позволяет найти время медленно +2часа и полный путь:
            <br />
            Весь путь 15 км/ч * 4 ч = 60 км или
            <br />
            10км/ч*6 ч = 60 кмs
          </p>,

          <p>
            Время вовремя - время, за которое нужно проехать полный путь.
            <br />4 ч + 1 ч= 5 ч (поскольку быстрый приехал на 1 час раньше)
          </p>,

          <p>
            Чтобы преодолеть путь 60 км вовремя (за 5 часов) скорость, с которой
            должен ехать велосипедист равна:
            <br />
            60 км : 5 ч = 12 км/ч
          </p>,

          <p>Ответ: 12 км/ч</p>,
        ]}
      />
    </div>
  );
}
