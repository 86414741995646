import React from "react";
import AuthInput from "../login/AuthInput";
import styles from "./Profile.module.scss";
import { TProfileInputProps } from "../../types/common/ProfileInputPropsType";

function ProfileInputWithLabel(props: TProfileInputProps) {
  return (
    <div className={styles.WithVerticalText}>
      <AuthInput
        id={props.id}
        label={props.label}
        name={props.id}
        type={props.type}
        value={props.value}
        onChange={props.handleChange}
      />
    </div>
  );
}

export default ProfileInputWithLabel;
