import Date, { IDateProps } from "../date/date";
import styles from "./header.module.scss";

interface IHeaderProps extends IDateProps {
  children?: any;
  title: string;
  imageSrc?: string;
}

function Header(props: IHeaderProps) {
  const { children, title, date, editedDate, imageSrc } = props;

  return (
    <div>
      <h1 className={styles.Title}>{title}</h1>

      <div className={styles.Background}>
        <img className={styles.Image} src={imageSrc} alt={""} />
      </div>

      <div className={styles.Text}> {children}</div>

      <Date date={date} editedDate={editedDate} />
    </div>
  );
}

export default Header;
