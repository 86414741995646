import { ReactNode } from "react";

export interface IScrollerProps {
  children: ReactNode;
  scrollToComponentName: string;
}

export function Scroller(props: IScrollerProps) {
  const { children, scrollToComponentName } = props;

  return <a href={`#${scrollToComponentName}`}>{children}</a>;
}
