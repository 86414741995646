import React, { useRef, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import styles from "./Answer.module.scss";
import { MathJaxOutput } from "../mathJax/MathJaxOutput";

function RadioGroupAnswer(props: any) {
  const ref = useRef();
  const onValueChange = async (event: any) => {
    setChecked(event.target.value.toString());
  };
  const [checked, setChecked] = useState("");
  return (
    <RadioGroup {...props} ref={ref}>
      {props.answerlist.map((answer: any) => (
        <FormControlLabel
          key={answer}
          label={<MathJaxOutput text={answer} />}
          control={
            <Radio
              value={answer}
              disabled={props.disabled}
              checked={
                props.answer ? props.answer === answer : checked === answer
              }
              onChange={onValueChange}
              className={styles.RadioButton}
            />
          }
        />
      ))}
    </RadioGroup>
  );
}

export default RadioGroupAnswer;
