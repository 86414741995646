import { ChangeEventHandler } from "react";
import styles from "./Form.module.scss";

interface IFormImageProps {
  id: string;
  key: string;
  title: string;
  name: string;
  imgTitle?: string;
  src?: string;
  base64CourseImage?: string;
  alt: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

function FormImage(props: IFormImageProps) {
  const { alt, id, imgTitle, name, onChange, title } = props;

  return (
    <div className={styles.Form} id={styles.indent}>
      <label className={styles.Label}>{title}</label>
      <div className={styles.InputHolder}>
        <input type="file" name={name} onChange={onChange} id={id}></input>

        {props.base64CourseImage ? (
          <img src={props.base64CourseImage} alt={alt} title={imgTitle} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default FormImage;
