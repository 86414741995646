import React from "react";
import Header from "../../../../shared/ui/header/header";
import styles from "../../../Page.module.scss";

export function HowAndWhyMathPage() {
  return (
    <div className={styles.Wrapper}>
      <div>
        <Header
          title="Сергей Рукшин. Как и зачем мы учим математике? Как учить девочек?"
          date={"3 сентября 2021"}
          imageSrc="/images/articles/article-header-test.png"
        >
          <p>
            <strong>Оригинальный текст на сайте "Фонтанка.Ру":</strong>
          </p>

          <a href="https://www.fontanka.ru/2021/09/03/70115405/">
            «Мой рекорд не побить, но он достигнут с горя». Легендарный учитель
            математики — о Перельмане и других своих учениках
          </a>
        </Header>

        <h2>"Как учить математику?"</h2>

        <p>
          «В основе технологии обучения науке математике в Матцентре лежит решение
          задач. То, что в школе ребенку преподается как теорема из учебника, —
          изначально для моих учеников задача, которая может быть названа в честь
          какого-нибудь математика. Такая технология преподавания приучает
          кружковцев ко всему относиться как к задаче. И это позволяет им довольно
          успешно выступать на олимпиадах».
        </p>

        <h2>Как обучать математике девочек?</h2>

        <p>
          «Девочек надо учить по-другому и в другом возрасте. У них сензитивный
          период активного обучения наступает раньше, чем у мальчиков. Так что
          идея раздельного обучения имеет не только гендерный подтекст, но и
          физиологические основания».
        </p>

        <h2>Зачем мы учим математик</h2>

        <p>
          «Цель — это систематическое изучение математики и подготовка к глубокой
          профессиональной деятельности в сфере математики и связанных с ней
          дисциплин. Это теоретическая физика, информатика, программирование
          высокого уровня. Многие мои ученики работают в Microsoft в США, они
          принадлежат к элите разработчиков. В Нью-Йорке на Манхэттене лучшие
          финансовые аналитики — мои ребята с дипломами и медалями международных
          математических олимпиад. Это результат развития мозга и умения думать
          над нерешенными проблемами, осваивать новые области знаний».
        </p>
      </div>
    </div>
  );
}
