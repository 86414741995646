import React from "react";
import List from "../../../../shared/ui/list/list";
import Date from "../../../../shared/ui/date/date";
import styles from "../../../Page.module.scss";
import { Table } from "@mui/material";
import { TableOfContents } from "../../../../widgets/tableOfContents/ui/tableOfContents";

export function Results2022Page() {
  return (
    <>
      <div id={"article"}>
        <div className={styles.Wrapper}>
          <h1 className={styles.Title}>
            Результаты вступительных экзаменов по математике учеников
            minimath239 в сезоне 2021/2022
          </h1>

          <Date date={"20 августа 2022"} />

          <h3 className={styles.Title}>Достижения сезона:</h3>

          <TableOfContents />

          <List
            data={[
              "Четверо наших учеников смогли успешно сдать экзамены по математике в ФМЛ 239 и набрать 50, 45, 39 и 33 балла из 60 возможных (проходной балл 31);",
              "Качественно выполнили экспресс подготовку к экзамену в ФМЛ 239, по 1-2 урока в день, начиная с 1 мая;",
              "Убедились, что наш курс для ФМЛ 239 применим для подготовки к поступлению в топовые московские школы;",
              "Продолжили бесплатную поддержку активных учеников, занимающихся на тренажере самостоятельно;",
              "Начали обучение нескольких учеников 1-2 класса.",
            ]}
          />

          <p>
            В таблице указаны школы, в пятые классы которых поступили наши
            ученики.
          </p>

          <Table>
            <tr>
              <th>№</th>
              <th>Город:</th>
              <th>Школа:</th>
              <th>Кол-во учеников:</th>
            </tr>

            <tr>
              <th>1</th>
              <th>СПБ</th>
              <th>610 школа</th>
              <th>5</th>
            </tr>

            <tr>
              <th>2</th>
              <th>СПБ</th>
              <th>ЛНМО</th>
              <th>4</th>
            </tr>

            <tr>
              <th>3</th>
              <th>СПБ</th>
              <th>ФМЛ 239</th>
              <th>3</th>
            </tr>

            <tr>
              <th>4</th>
              <th>СПБ</th>
              <th>ФМЛ 30</th>
              <th>1</th>
            </tr>

            <tr>
              <th>5</th>
              <th>СПБ</th>
              <th>ФМЛ 366</th>
              <th>1</th>
            </tr>

            <tr>
              <th>6</th>
              <th>СПБ</th>
              <th>ЮМШ</th>
              <th>1</th>
            </tr>

            <tr>
              <th>7</th>
              <th>Москва</th>
              <th>ФМЛ 2007</th>
              <th>1</th>
            </tr>

            <tr>
              <th>8</th>
              <th>Москва</th>
              <th>444 школа</th>
              <th>1</th>
            </tr>

            <tr>
              <th>9</th>
              <th>Москва</th>
              <th>ФТЛ им. Капицы</th>
              <th>1</th>
            </tr>

            <tr>
              <th>10</th>
              <th>Москва</th>
              <th>Воробьевы горы</th>
              <th>1</th>
            </tr>

            <tr>
              <th>11</th>
              <th>Москва</th>
              <th>1533 ЛИТ</th>
              <th>1</th>
            </tr>

            <tr>
              <th>12</th>
              <th>Москва</th>
              <th>1502</th>
              <th>1</th>
            </tr>

            <tr>
              <th>13</th>
              <th>Регионы РФ</th>
              <th>ФТЛ Томск</th>
              <th>1</th>
            </tr>

            <tr>
              <th>Итого:</th>
              <th />
              <th />
              <th>22</th>
            </tr>
          </Table>

          <h3 className={styles.Title}>
            Подробные результаты наших учеников, поступавших в 5 класс:
          </h3>

          <br />

          <img src={"/images/achievements/2022_table_result.png"} alt={""} />
        </div>
      </div>
    </>
  );
}
