import React from "react";
import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import { LoginForm } from "../../../widgets/loginForm";

export function LoginPage(props: { type: string }) {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, props.type);

  return <LoginForm type={props.type} />;
}
