import { Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import useCookie from "react-use-cookie";
import { AuthService } from "../../../APIService/AuthService";
import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import { Button } from "../../../shared/ui/button";
import { useSnackbar } from "notistack";

export function ConfirmEmailPage() {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "confirm-email");

  const props = useParams();
  const [isConfirmed, setIsConfirmed] = useCookie("auth", "");
  const { enqueueSnackbar } = useSnackbar();

  const onClick = async () => {
    await AuthService.confirmEmail(props.token!)
      .then(() => {
        enqueueSnackbar("Почта подтверждена!", {
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        setIsConfirmed("true", { days: 365 });
      })
      .catch((e) =>
        enqueueSnackbar(e.response.data.message || e.response.data.error, {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        }),
      );
  };

  return (
    <Grid container>
      {isConfirmed === "true" ? (
        <div>
          <Grid item sm={12} md={12} lg={12}>
            <p />
            <p />
            <p>Регистрация завершена! Можете закрыть это окно! </p>
          </Grid>
        </div>
      ) : (
        <div>
          <Grid item sm={12} md={12} lg={12}>
            <p />
            <p />
            <p>
              {" "}
              Вы находитесь на странице подтверждения электронной почты.
              Завершите процесс регистрации, нажав кнопку ниже:{" "}
            </p>
          </Grid>
          <Grid item sm={10}>
            <Button onClick={onClick}> Подтверждаю</Button>
          </Grid>
        </div>
      )}
    </Grid>
  );
}
