import { Link } from "react-router-dom";
import { Button } from "../../../shared/ui/button";
import styles from "./preparationFormat.module.scss";

export function PreparationFormat() {
  return (
    <div className={styles.wrapper}>
      <div>
        <h2 className={styles.title}>Формат подготовки</h2>

        <p>
          Вы можете готовиться в мини-группе по полноценной программе или
          заниматься с преподавателем индиивдуально. Оба формата хороши, но у
          каждого есть свои преимущества.
        </p>

        <p>
          Мы поможем подобрать оптимальный способ обучения и ответим на все
          вопросы на бесплатной консультации.
        </p>

        <Link to={"/"}>
          <Button styleClass={styles.button}>
            <span>Записаться</span>
          </Button>
        </Link>
      </div>
    </div>
  );
}
