import React from "react";
import FinalOlympiadStageHeader from "../../../../components/articles/finalOlympiadStage/FinalOlympiadStageHeader";
import WhyFmlIsStrongest from "../../../../components/articles/finalOlympiadStage/WhyFmlIsStrongest";
import styles from "../../../Page.module.scss";

export function FinalOlympiadStagePage() {
  return (
    <div className={styles.Wrapper}>
      <FinalOlympiadStageHeader />
      <WhyFmlIsStrongest />
    </div>
  );
}
