import { createContext, useContext, useEffect, useState } from "react";
import { TUserInfo } from "../types/UserInfoType";
import { UserService } from "../APIService/UserService";
import { useAuthContext } from "./authContext";

/**
 * Контекст информации о пользователе
 */

export type TUserInfoContext = {
  userInfo: TUserInfo;
  // setUserInfo: Dispatch<SetStateAction<TUserInfo>>,
  handleChange: (e: any) => void;
};

export const UserInfoContext = createContext<TUserInfoContext>({
  userInfo: {
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    nickname: "",
  },
  // setUserInfo: emptyFunction,
  handleChange: () => {},
});

function UserInfoProvider(props: { children: any }) {
  const { authInfo, isAuth } = useAuthContext();
  const [userInfo, setUserInfo] = useState<TUserInfo>({
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    nickname: "",
  });

  const handleChange = (e: any) => {
    setUserInfo((editItem) => {
      let item = Object.assign({}, editItem);
      // @ts-ignore
      item[e.target.id] = e.target.value;
      return item;
    });
  };

  useEffect(() => {
    const userInfoFetch = async () => {
      if (localStorage.getItem("auth") && authInfo.id > 0) {
        const response = await UserService.getUserInfo(authInfo.id);
        // replace all null values to empty strings:
        Object.keys(response.data).forEach(
          (k) =>
            (response.data[k] =
              response.data[k] === null ? "" : response.data[k]),
        );
        setUserInfo(response.data);
      }
    };
    userInfoFetch();
  }, [authInfo.id, isAuth]);

  return (
    <UserInfoContext.Provider value={{ userInfo, handleChange }}>
      {props.children}
    </UserInfoContext.Provider>
  );
}

export default UserInfoProvider;
export const useUserInfoContext = () => {
  return useContext(UserInfoContext);
};
