import React from "react";
import Date from "../../../../shared/ui/date/date";
import List from "../../../../shared/ui/list/list";
import styles from "../../../Page.module.scss";

export function ChangesToLessonsPage() {
  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Title}>
        Изменения условий индивидуальных занятий на период январь - май 2020
        года.
      </h1>

      <Date date={"1 января 2020"} />

      <p>
        Уважаемые родители, вносим изменения в условия индивидуальных занятий на
        период январь-май 2020.s
      </p>

      <List
        data={[
          "Занятия с учениками 3х классов по обычным условиям  - 1125руб/45 мин. Места есть.",
          "Занятия с учениками 4х классов по обычным условиям  - мест нет. Открыта запись в лист ожидания.",
          "Специальные условия для учеников 4х классов: занятия по цене 3000 руб/1 час занятия (стоимость первого пробного занятия 1125 руб 45 мин.)",
          "Вебинары по разбору заданий из тестов. Стоимость 500 руб. Проводятся по мере формирования групп от 6 чел.",
        ]}
      />

      <p>
        Запросы на проведение занятия направлять по адресу{" "}
        <a href={"mailto:minimath239@yandex.ru"}>minimath239@yandex.ru</a>
      </p>
    </div>
  );
}
