import styles from "../../../Page.module.scss";
import Date from "../../../../shared/ui/date/date";
import { Table } from "@mui/material";
import React from "react";

export function Results2020Page() {
  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Title}>
        Результаты вступительных экзаменов по математике учеников minimath239 в
        сезоне 2019/2020
      </h1>

      <Date date={"1 сентября 2020"} />

      <p>
        Подвели итоги прошедшего сезона подготовки к поступлению в 5-е классы
        физико-математических лицеев Санкт-Петербурга и Москвы.
        <br />
        Поздравляем всех ребят! Спасибо за ваш труд, упорство и интерес к
        математике.
      </p>

      <p>
        В таблице указаны школы, в пятые классы которых поступили наши ученики.
      </p>

      <Table>
        <tr>
          <th>№</th>
          <th>Город:</th>
          <th>Школа:</th>
          <th>Кол-во учеников:</th>
        </tr>

        <tr>
          <th>1</th>
          <th>СПБ</th>
          <th>ФМЛ 239</th>
          <th>2</th>
        </tr>

        <tr>
          <th>2</th>
          <th>СПБ</th>
          <th>ФМЛ 30</th>
          <th>1</th>
        </tr>

        <tr>
          <th>3</th>
          <th>СПБ</th>
          <th>ЮМШ</th>
          <th>1</th>
        </tr>

        <tr>
          <th>4</th>
          <th>СПБ</th>
          <th>ФМЛ 366</th>
          <th>1</th>
        </tr>

        <tr>
          <th>5</th>
          <th>СПБ</th>
          <th>610 школа</th>
          <th>1</th>
        </tr>

        <tr>
          <th>6</th>
          <th>СПБ</th>
          <th>777 школа</th>
          <th>1</th>
        </tr>

        <tr>
          <th>7</th>
          <th>Москва</th>
          <th>1502 школа</th>
          <th>1</th>
        </tr>

        <tr>
          <th>Итого:</th>
          <th />
          <th />
          <th>8</th>
        </tr>
      </Table>

      <h3 className={styles.Title}>
        Подробные результаты наших учеников, поступавших в 5 класс:
      </h3>

      <img src={"/images/achievements/2020_table_result.png"} alt={""} />

      <p>Гордимся результатами Антона А., поступившего во все школы. Браво!</p>

      <p>
        Желаем всем ребятам удачи на новом этапе вашей жизни.
        <br />
        Спасибо всем родителям за ваш выбор курса minimath239.
      </p>
    </div>
  );
}
