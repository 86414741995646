import React from "react";
import { PageCard } from "../../../widgets/pageCard";
import styles from "./articlesPage.module.scss";
import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";

export function ArticlesPage() {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "articles");

  return (
    <div className={styles.Wrapper}>
      <h2 className={styles.Title}>Статьи</h2>

      <PageCard
        title={"Обучение с преподавателем"}
        pageLink={"/teaching-lessons"}
      >
        Расскажем подробнее и команде и процессе обучения на minimath.
      </PageCard>

      <PageCard
        title={
          "Заключительный этап Всероссийской олимпиады по математике – ФМЛ 239 снова на коне!"
        }
        pageLink={"/articles/fml-239-lider-vsosch-2022"}
      >
        Вот и закончилась Всероссийская олимпиада школьников по математике. ФМЛ
        239 получил 13 первых мест из 34.
      </PageCard>

      <PageCard
        title={
          "Сергей Рукшин. Как и зачем учится математике? Как учить девочек?"
        }
        pageLink={
          "/articles/sergej-rukshin-kak-i-zachem-uchitsya-matematike-kak-uchit-devochek"
        }
      >
        О том, как меняется мир математики высоких достижений, когда в него
        приходят большие деньги и родительские амбиции, в интервью «Фонтанке»
        рассказал народный учитель России, руководитель знаменитого Матцентра,
        воспитавший лауреатов премии Филдса Григория Перельмана и Станислава
        Смирнова, профессор Государственного педагогического университета им. А.
        И. Герцена Сергей Рукшин
      </PageCard>

      <PageCard
        title={
          "Секреты ФМЛ 239 или почему лицей побеждает москвичей на олимпиадах"
        }
        pageLink={
          "/articles/sekrety-fml-239-ili-pochemu-litsej-pobezhdaet-moskvichej-na-olimpiadakh"
        }
      >
        У minimath239 есть реальный пример конкуренции Москвы и Петербурга.
        Родители нашего ученика, вполне вероятного победителя будущих
        всероссийских олимпиад, выбирая между Москвой и СПб, целенаправленно
        переехали в Санкт-Петербург из города "в 80 км о т Москвы", для того,
        чтобы поступить в 5 класс ФМЛ 239.
      </PageCard>

      <PageCard
        title={"Решение задачи про приехавших раньше и опаздывающих"}
        pageLink={
          "/articles/the-problem-is-about-those-who-arrived-earlier-and-those-who-are-late"
        }
      >
        Задача: Велосипедист должен попасть в место назначения к определенному
        сроку. Известно, что если он поедет со скоростью 15 км/ч, то приедет на
        1 час раньше, а если со скоростью 10 км/ч, то опоздает на 1 час. С какой
        скоростью он должен ехать?
      </PageCard>

      <PageCard
        title={"Особенности тренажера"}
        pageLink={"/articles/osobennosti-trenazhera-minimath239"}
      >
        Скриншоты с отчетами и проверенными ответами. Советы по вводу ответов.
      </PageCard>

      <PageCard
        title={
          "ФМЛ 239 – лучшая школа России. Мнение главного тренера сборной России по математике Кирилла Сухова"
        }
        pageLink={
          "/articles/fml-239-luchshaya-shkola-rossii-mnenie-glavnogo-trenera-sbornoj-rossii-po-matematike-kirilla-sukhova"
        }
      >
        Беседа Кирилла Андреевича Сухова и ректора Адыгейского государственного
        университета Дауда Казбековича Мамия во время Кавказской математической
        олимпиады.
      </PageCard>
    </div>
  );
}
