import Header from "../../../shared/ui/header/header";
import List from "../../../shared/ui/list/list";

function FeatureOfMinimathHeader() {
  return (
    <div>
      <Header
        title={"Особенности тренажера Minimath239"}
        date={"30 августа 2022"}
        imageSrc="/images/articles/article-header-test.png"
      />

      <p>
        1. Неправильный ответ выделяется розовым цветом, а внизу появляется
        подсказка.
      </p>

      <img src={"/images/features-of-minimath/figure1.png"} alt={""} />

      <p>
        2. Правильный ответ выделяется зеленым цветом.
        <br />
        Для важных задач на зеленом фоне появляется рекомендуемое решение.
      </p>

      <img src={"/images/features-of-minimath/figure2.png"} alt={""} />

      <p>
        3. После того, как задача решена правильно, пропадает возможность
        повторного ввода ответа.
      </p>

      <img src={"/images/features-of-minimath/figure3.png"} alt={""} />

      <p>
        4. Страница «Результаты». Показывает ответы, которые вводил ученик и
        результат:
      </p>

      <List
        data={[
          "«Решено с 1-й попытки»",
          "«Не верно» - все введенные ответы ошибочные.",
          "«Пусто» - по задаче не введено ни одного ответа.",
        ]}
      />

      <img src={"/images/features-of-minimath/figure4.png"} alt={""} />

      <p>
        5. Страница «Обучение». Показывает все курсы, назначенные ученику.
        Назначение полей:
      </p>

      <List
        data={[
          "«Верно» - есть хотя бы 1 правильный ответ",
          "«Не верно» - все введенные ответы ошибочные.",
          "«Пусто» - по задаче не введено ни одного ответа.",
        ]}
      />

      <img src={"/images/features-of-minimath/figure5.png"} alt={""} />

      <p>
        6. При вводе в ответ нескольких чисел, их очередность не имеет значения.
      </p>

      <p>
        В данной задаче ответы 44,45,46,47 и 45,44,47,46 будут признаны
        одинаково правильными.
      </p>

      <img src={"/images/features-of-minimath/figure6.png"} alt={""} />

      <p>
        7. Дополнительные вопросы от minimath239 позволяют точнее находить
        слабые места в знаниях ученика и минимизируют затраты времени ученика
        при попытках угадать ответ.
      </p>

      <img src={"/images/features-of-minimath/figure7.png"} alt={""} />

      <p>
        8. Решения сложных задач необходимо записывать на электронной доске.
      </p>

      <img src={"/images/features-of-minimath/figure8.png"} alt={""} />
    </div>
  );
}

export default FeatureOfMinimathHeader;
