import { ReactNode } from "react";
import Date, { IDateProps } from "../../../shared/ui/date/date";
import styles from "./pageCard.module.scss";

export interface IPageCardProps extends IDateProps {
  children?: ReactNode | string;
  title: string;
  pageLink?: string;
}

export function PageCard(props: IPageCardProps) {
  const { children, title, pageLink, date, editedDate } = props;

  return (
    <div className={styles.Background}>
      <div className={styles.Card}>
        <a className={styles.Link} href={pageLink}>
          {title}
        </a>

        <Date date={date} editedDate={editedDate} />

        <p>{children}</p>
      </div>
    </div>
  );
}
