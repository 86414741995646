import React, { useEffect, useState } from "react";
import styles from "./Task.module.scss";
import { useParams } from "react-router-dom";
import { TTask } from "../../types/TaskType";
import { TCourse } from "../../types/CourseType";
import { useCourseContext } from "../../context/courseContext";
import { CourseTypeEnum } from "../../constants/enums";
import { useAuthContext } from "../../context/authContext";
import TaskFooter from "./TaskFooter";
import TaskHeader from "./TaskHeader";
import TaskItemLayer from "./TaskItemLayer";
import Loading from "../../shared/ui/loading/loading";
import { Fab, Tooltip } from "@mui/material";
import { CoursesNavigationButtons } from "./CoursesNavigationButtons";
import { Button } from "../../shared/ui/button";

type TTaskListProps = {
  tasks: TTask[];
  courseType: CourseTypeEnum;
  course: TCourse;
  isForAdmin?: boolean;
};

function TaskList(props: TTaskListProps) {
  const { tasks, courseType, course, isForAdmin } = props;

  const {
    handleSubmit,
    isClickedCheck,
    setIsClickedCheck,
    isCheckButtonDisabled,
    setCheckButtonDisabled,
    hasForbiddenSymbols,
    checkedTasks,
    answersList,
    courseIds,
    resetAll,
    setIteration,
  } = useCourseContext();
  const params = useParams();
  const { authInfo } = useAuthContext();

  const [isCoursesLoading, setIsCoursesLoading] = useState<boolean>(false);

  document.title = course.name ? course.name : "Задача";

  const onClick = async (e: any) => {
    e.preventDefault();
    setIsCoursesLoading(true);
    await handleSubmit(e).then(() => setIsCoursesLoading(false));
  };

  const currentIdIndex = courseIds
    ? courseIds.indexOf(Number(params.id))
    : null;
  const prevId =
    currentIdIndex === 0 ? null : courseIds[(currentIdIndex || 1) - 1] || null;

  const nextId = courseIds[(currentIdIndex || 0) + 1] || null;

  const checkedTaskIds = checkedTasks.map((y: any) => y.taskId);

  const answeredTasksWithRoot = tasks.filter(
    (x) =>
      checkedTasks.map((z: any) => z.rootId).includes(x.id) ||
      checkedTaskIds.includes(x.id),
  );

  const tasksToShow = !isClickedCheck ? tasks : answeredTasksWithRoot;

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   return () => {
  //     resetAll();
  //   };
  // }, [resetAll]);

  useEffect(() => {
    setCheckButtonDisabled(
      !!hasForbiddenSymbols.length || !Object.keys(answersList).length,
    );
  }, [hasForbiddenSymbols, answersList, setCheckButtonDisabled]);

  if (!tasks) {
    return <h1 style={{ textAlign: "center" }}>Нет задач!</h1>;
  }

  return (
    <div>
      {isCoursesLoading ? (
        <div className={styles.centeredContent}>
          <Loading />
        </div>
      ) : (
        <div>
          <TaskHeader
            course={course}
            isClickedCheck={isClickedCheck}
            authInfo={authInfo}
          />

          <TaskItemLayer
            checkedTasks={checkedTasks}
            isClickedCheck={isClickedCheck}
            tasksToShow={tasksToShow}
            answersList={answersList}
          />

          {!isClickedCheck && !isForAdmin ? (
            <CoursesNavigationButtons
              previousId={prevId}
              nextId={nextId}
              courseType={courseType}
            />
          ) : (
            <></>
          )}
          {isClickedCheck ? (
            <div className={styles.BackToTasksButton}>
              <Button
                onClick={() => {
                  resetAll();
                  setIteration((prevState) => prevState + 1);
                  setIsClickedCheck(false);
                }}
              >
                Вернуться к задачам
              </Button>
            </div>
          ) : (
            <div className={styles.ButtonLayer}>
              <Tooltip title={"Проверить ответы"}>
                <span>
                  <Fab
                    size="large"
                    variant="extended"
                    disabled={isCheckButtonDisabled}
                    sx={{
                      width: 300,
                      backgroundColor: "#EB5E70",
                      "&:hover": {
                        backgroundColor: "#EB5E70",
                        color: "#FFFFFF",
                      },
                    }}
                    onClick={onClick}
                    aria-label="save"
                  >
                    Проверить ответы
                  </Fab>
                </span>
              </Tooltip>
            </div>
          )}
          <TaskFooter course={course} isClickedCheck={isClickedCheck} />
        </div>
      )}
    </div>
  );
}

export default TaskList;
