import React from "react";
import TeamMemberForm from "../../../components/ourTeam/TeamMemberForm";
import { TeamMember } from "../types/teamMember";
import styles from "./ourTeamContainer.module.scss";

export function OurTeamContainer() {
  const members: TeamMember[] = [
    {
      name: "Фефелова Ольга",
      description:
        "Руководитель проекта, выпускница факультета Прикладная математика Санкт-Петербургского государственного университета.",
      imageSrc: "images/our-team/Our_Team_5_14.jpg",
    },

    {
      name: "Барышников Вячеслав",
      description:
        "Технический директор, выпускник факультета Прикладная математика Санкт-Петербургского государственного университета.",
      imageSrc: "images/our-team/Our_Team_5_0000.jpg",
    },

    {
      name: "Артюхов Антон",
      description: "Ученик ФМЛ 239, Матцентр с 5 класса, перешел в 9 класс",
      imageSrc: "images/our-team/Our_Team_5_08.jpg",
    },

    {
      name: "Галкин Игорь",
      description: "3-9 класс, Кандидат наук",
      imageSrc: "images/our-team/Our_Team_5_06.jpg",
    },

    {
      name: "Гуль Татьяна",
      description:
        "Репетитор с 15 летним стажем, выпускница Челябинского Государственного Педагогического Института.",
      imageSrc: "images/our-team/Our_Team_5_04.jpg",
    },

    {
      name: "Евдокимов Сергей",
      description: "Выпускник физмата СПБГУ",
      imageSrc: "images/our-team/Our_Team_5_0003.jpg",
    },

    {
      name: "Ефремова Светлана",
      description: "Добавление новых задач на сайт",
      imageSrc: "images/our-team/Our_Team_5_03.jpg",
    },

    {
      name: "Жидков Владимир",
      description:
        "Выпускник факультета Прикладная математика Санкт-Петербургского государственного университета.",
      imageSrc: "images/our-team/Our_Team_5_12.jpg",
    },

    {
      name: "Жидков Александр",
      description: "Программист",
      imageSrc: "images/our-team/Our_Team_5_0005.jpg",
    },

    {
      name: "Игнаткин Вячеслав",
      description: "4-11, Матцентр, Руководитель направления ЕГЭ",
      imageSrc: "images/our-team/Our_Team_5_18.jpg",
    },

    {
      name: "Ковалев Максим",
      description: "Дизайнер",
      imageSrc: "images/our-team/Our_Team_5_10.jpg",
    },

    {
      name: "Корнеев Алексей",
      description: "Юрист",
      imageSrc: "images/our-team/",
    },

    {
      name: "Лаврикова Лена",
      description: "Бухгалтер",
      imageSrc: "images/our-team/",
    },

    {
      name: "Липатова Ирина",
      description: "4-8 класс",
      imageSrc: "images/our-team/Our_Team_5_0002.jpg",
    },

    {
      name: "Образцова Анастасия",
      description:
        "Добавление новых задач на сайт. Создание курсов для обучения для 1-2 класса",
      imageSrc: "images/our-team/Our_Team_5_0006.jpg",
    },

    {
      name: "Орловская Маргарита",
      description:
        "Добавление новых задач на сайт. Создание курсов для обучения для 1-2 класса",
      imageSrc: "images/our-team/Our_Team_5_26.jpg",
    },

    {
      name: "Сычев Денис",
      description: "Ведущий программист",
      imageSrc: "images/our-team/Our_Team_5_0004.jpg",
    },

    {
      name: "Тарасова Анна",
      description: "4-8 класс, Лицей вторая школа",
      imageSrc: "images/our-team/Our_Team_5_01.jpg",
    },

    {
      name: "Фадеева Наталья",
      description: "3-11, 2 тур ФМЛ 239, ЕГЭ",
      imageSrc: "images/our-team/",
    },

    {
      name: "Финдейзен Юлия",
      description: "3-5 класс",
      imageSrc: "images/our-team/Our_Team_5_32.jpg",
    },

    {
      name: "Ханмагомедова Мелек",
      description: "Призер московских олимпиад, перешла в 7 класс",
      imageSrc: "images/our-team/Our_Team_5_02.jpg",
    },

    {
      name: "Хусточка Евгений",
      description: "Программист - психологический тест Тулуз-Пьерона",
      imageSrc: "images/our-team/Our_Team_5_30.jpg",
    },

    {
      name: "Чернова Антонина",
      description: "4-8 Олимпиадная математика",
      imageSrc: "images/our-team/Our_Team_5_28.jpg",
    },

    {
      name: "Шпадарук Наталья",
      description: "1-3 класс",
      imageSrc: "images/our-team/Our_Team_5_05.jpg",
    },

    {
      name: "Штейнберг Илья",
      description: "1-4 класс",
      imageSrc: "images/our-team/Our_Team_5_20.jpg",
    },
  ];

  return (
    <div className={styles.Wrapper}>
      {members.map((member) => (
        <TeamMemberForm
          name={member.name}
          descrition={member.description}
          imageSrc={member.imageSrc}
        />
      ))}
    </div>
  );
}
