import { OurValues } from "../../../widgets/ourValues";
import { PageHeader } from "../../../widgets/pageHeader";
import { PreparationFormat } from "../../../widgets/preparationFormat/ui/preparationFormat";
import { StudyFlow } from "../../../widgets/studyFlow";
import { Teacher } from "../../../widgets/teacher";
import styles from "./mainEgePage.module.scss";

export function MainEgePage() {
  document.title = "Главная ЕГЭ";

  return (
    <>
      <PageHeader title={
        <>
          ОНЛАЙН ТРЕНАЖЕР И РЕПЕТИТОРЫ <br /> ДЛЯ УЧЕНИКОВ 2-4 КЛАССА, <br />
          ПОСТУПАЮЩИХ В ФМЛ
        </>
      }>
        <>
          Подготовка к поступлению в математические классы <br /> школ Москвы и
          Санкт-Петербурга
        </>
      </PageHeader>

      <div className={styles.Wrapper}>
        <PreparationFormat />
        <StudyFlow />
        <OurValues />
        <Teacher />
      </div>
    </>
  );
}
