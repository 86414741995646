import React from "react";
import Header from "../../../shared/ui/header/header";
import List from "../../../shared/ui/list/list";
import { LessonCost } from "../../../widgets/lessonCost";
import styles from "../../Page.module.scss";

export function TeachingPage() {
  document.title = "Обучение с учителем";
  return (
    <div className={styles.Wrapper}>
      <div>
        <Header
          title="Уроки с преподавателем"
          imageSrc="/images/articles/article-header-test.png"
        >
          <p>
            <strong>Дорогие родители, спасибо, что пришли на наш сайт</strong>
          </p>

          <p>
            <strong>
              Я, Фефелова Ольга, репетитор по математике. Готовлю ребят 7-11 лет к
              поступлению в математические классы и физико-математические лицеи.
            </strong>
          </p>
        </Header>

        <p>
          С июня 2017 года, мы небольшой командой математиков-единомышленников,
          выпускников факультета Прикладная математика Санкт-Петербургского
          государственного университета, разработали тренажер, который упрощает
          работу репетиторов и повышает эффективность подготовки ребят.
        </p>

        <p>
          Ребенок не должен быть гением. Он должен быть трудолюбивым, внимательным
          к ошибкам, стрессоустойчивым и знать больше школьной программы. На наш
          взгляд, почти любой ребенок может поступить в 5 класс ФМЛ, если начать с
          1-2 класса и если родители готовы активно поддерживать обучение ребенка.
        </p>

        <p>
          <strong>
            Опираясь на эти требования, мы разработали методику, которая
            позволяет:
          </strong>
          <List
            data={[
              'Учитывать вероятность появления тем на экзаменах и не тратить время на решение "лишних" задач, которые дадут маленький рост вероятности успешного поступления,',
              'Проверять грубину понимания пройденных тем и отличать "понимание" от "запоминания",',
              "Находить слабые места и развивать проблемные навыки,",
              "Измерять и улучшать скорость решения задач и вычислений.",
            ]}
          />
        </p>
      </div>

      <LessonCost />
    </div>
  );
}
