import { useTableOfContents } from "../hooks/useTableOfContents";
import styles from "./tableOfContents.module.scss";

export function TableOfContents() {
  const headings = useTableOfContents("#article h2,#article h3");

  return (
    <div className={styles.Container}>
      <ul>
        {headings.map((heading) => (
          <li key={heading.id}>
            <a href={`#${heading.id}`}>{heading.text}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}
