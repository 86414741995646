import styles from "./Form.module.scss";

function FormFile(props: any) {
  return (
    <div className={styles.Form} id={styles.indent}>
      <label className={styles.Label}>{props.title}</label>
      <div className={styles.InputHolder}>
        <input
          className="form-control"
          type="file"
          name={props.name}
          id={props.id}
          onChange={props.onChange}
        ></input>
      </div>
    </div>
  );
}

export default FormFile;
