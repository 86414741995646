import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import { ChangePasswordForm } from "../../../widgets/changePasswordForm";
import { DeleteAccountForm } from "../../../widgets/deleteAccountForm";
import { ProfileInformation } from "../../../widgets/profileInformation";
import styles from "./profilePage.module.scss";

export function ProfilePage() {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "account");

  return (
    <div className={styles.Wrapper}>
      <ProfileInformation />
      <ChangePasswordForm />
      <DeleteAccountForm />
    </div>
  );
}
