import { $host } from "./index";
import { TEditTask } from "../types/EditTaskType";
import { TEditCourse } from "../types/EditCourseType";

export default class AdminService {
  static async getCourseById(id: number) {
    return await $host.get("admin/course/" + id);
  }

  static async getTaskById(id: number) {
    return await $host.get("admin/task/" + id);
  }

  static async putUpdatedTask(data: TEditTask) {
    return await $host.put("admin/task/" + data.id, data);
  }

  static async putUpdatedCourse(data: TEditCourse) {
    return await $host.put("admin/course/" + data.id, data);
  }

  static async createTask(data: TEditTask) {
    data.id = undefined;
    return await $host.post("admin/task", data);
  }

  static async createCourse(data: TEditCourse) {
    data.id = undefined;
    return await $host.post("admin/course", data);
  }

  /**
   * Метод для загрузки файла
   * @param data - обновленные данные
   * @param type - тип файла (course or task)
   * @param field - поле для обновления в БД (answerImage, questionImage, image)
   * @param id - айди строки для обновления
   * @return {Promise<any>}
   */
  static async uploadImage(
    data: any,
    type: string,
    field: string,
    id?: number,
  ) {
    return await $host.post("admin/file/" + type + "/" + id, data, {
      body: { field },
    });
  }

  /**
   * Отчет 25
   * @param id - айди
   * @param days - количество дней, по которым вести учет
   */
  static async getAnswer25Info(id: number, days: number) {
    return await $host.get("admin/answer25", { params: { id, days } });
  }
}
