import styles from "./Form.module.scss";

function FormText(props: any) {
  return (
    <div className={styles.Form} id={styles.indent}>
      <label className={styles.Label}>{props.title}</label>
      <div className={styles.InputHolder}>
        <input {...props} />
      </div>
    </div>
  );
}

export default FormText;
