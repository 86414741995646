import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthService } from "../../../APIService/AuthService";
import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import { Button } from "../../../shared/ui/button";
import styles from "../../../widgets/loginForm/ui/loginForm.module.scss";
import AuthInput from "../../../components/login/AuthInput";
import { useSnackbar } from "notistack";

export function ResetPasswordPage() {
  const props = useParams();
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "reset");

  const navigate = useNavigate();
  const [state, setState] = useState<{
    newPassword: string;
    repeatedPassword: string;
  }>({
    newPassword: "",
    repeatedPassword: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const onClick = async () => {
    if (state?.newPassword !== state?.repeatedPassword) {
      enqueueSnackbar(
        "Введённые пароли не совпадают!" +
          " Проверьте правильность данных и повторите попытку снова!",
        {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        },
      );
      return;
    }
    await AuthService.changePassword(props.token!, state.newPassword)
      .then(() => {
        enqueueSnackbar("Пароль успешно изменен!", {
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        navigate(`/`);
      })
      .catch((e) =>
        enqueueSnackbar(e.response.data.message || e.response.data.error, {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        }),
      );
  };

  return (
    <div>
      <Grid
        direction={"column"}
        container
        className={styles.LoginContainer}
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Grid item xs={12} sm={12} md={12} className={styles.Title}>
          <h3>Восстановление пароля</h3>
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.Title} />
            <AuthInput
              type="password"
              placeholder="Новый пароль"
              name="newPassword"
              value={state.newPassword}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.Title} />
            <AuthInput
              type="password"
              placeholder="Повторите пароль"
              name="repeatedPassword"
              value={state.repeatedPassword}
              onChange={handleChange}
            />
          </Grid>
          <p />
          <p />
        </Grid>
        <Button onClick={onClick} type={"submit"}>
          Изменить
        </Button>
      </Grid>
    </div>
  );
}
