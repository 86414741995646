import { $host } from "./index";

export default class CommonService {
  static async getSitePages() {
    return await $host.get("page-decorations");
  }
  static async getPageDecoration(page: string) {
    // TODO: describe return schema in API and import DTO in thios project
    const result = await $host.get("page-decorations/" + page);
    return result.data;
  }
}
