import React, { Fragment } from "react";
import Header from "../../../shared/ui/header/header";
import List from "../../../shared/ui/list/list";
import { Note } from "../../../widgets/note";

function FmlSecretsHeader() {
  return (
    <>
      <Header
        title={
          "Секреты ФМЛ 239 или почему лицей побеждает москвичей на олимпиадах"
        }
        date={"8 мая 2021"}
        editedDate="8 мая 2022"
        imageSrc="/images/articles/article-header-test.png"
      >
        <p>
          <strong>
            На форуме московских родителей Eva RU идет активное обсуждение
            Санкт-Петербургского физико-математического лицея №239 и сравнение
            его со школами Москвы. Почему дети 239 лицея показывают отличные
            результаты на олимпиадах и турнирах и что не так в ведущих физмат
            лицеях Москвы? Смотрите ниже "Итоги всероссийской олимпиады по
            математике 2021 года".
          </strong>
        </p>
      </Header>

      <p>
        Мой краткий ответ (подробный ответ читайте ниже):
        <List
          data={[
            "Стабильный, сильный и независимый директор",
            "Исключительно сильное дополнительное образование.",

            "Открытость в требованиях к поступлению",
            "Сложившаяся иерархия школ города, которую признают все родители",
            "Территория влияния (возможность отбора талантов)",
          ]}
        />
      </p>

      <p>
        У minimath239 есть реальный пример конкуренции Москвы и Петербурга.
        Родители нашего ученика, вполне вероятного победителя будущих
        всероссийских олимпиад, выбирая между Москвой и СПб, целенаправленно
        переехали в Санкт-Петербург из города "в 80 км от Москвы", для того,
        чтобы поступить в 5 класс ФМЛ 239.
      </p>

      <Note>
        «Долго мы к этому шли и вот теперь наш сын, преодолев конкурс{" "}
        <strong>18 человек на место</strong>, ученик Президентского ФМЛ 239!
        Лучшая школа России, аналогов которой нет не только в нашей стране!{" "}
        <strong>Математический центр</strong>, который воспитал Перельмана,
        Смирнова; <strong>Робототехника</strong>, которая входит в учебную
        программу, с учителем С.А. Филипповым - тренером победителей
        международной олимпиады по робототехнике, <strong>Физика</strong> с
        тренером нашей российской команды на международной олимпиаде,{" "}
        <strong>Русским и литературой</strong>, которые являются первостепенными
        предметами, правилами, по которым всех учеников называют на Вы, и
        применение физической силы между учениками объявляется в уставе лицея
        унижением достоинства человека, а телефоны разрешаются только кнопочные
        и доставать их нельзя; <strong>Электротехника</strong>,{" "}
        <strong>программирование</strong>, и ещё дополнительный кружок{" "}
        <strong>"что, где, когда"</strong>, который у детей ведет Александр
        Друзь; трудовой лагерь Тихвинец, куда дети, правда старшеклассники,
        выезжают на работы по восстановлению монастыря, таскают кирпичи,
        трудятся на земле; <strong>директор</strong>, который является членом
        совета президента по образованию, и сегодня в смешном костюме Эйнштейна
        раздал всем детям по мороженому. Но главное – это горящие глаза всех
        детей, учащихся здесь, и учителей, преподающих здесь и потрясающая
        атмосфера, братство на всю жизнь! Это невозможно описать и мы счастливы!
        Пусть всё сложится!»
      </Note>

      <p>
        Эти слова написаны в эйфории победы, но они показывают то, за что
        соревнуются дети и их родители, решая поступать в лицей 239.
      </p>
    </>
  );
}

export default FmlSecretsHeader;
