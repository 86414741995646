import List from "../../../../shared/ui/list/list";
import Date from "../../../../shared/ui/date/date";
import React from "react";
import styles from "../../../Page.module.scss";
import { Table } from "@mui/material";

export function Results2021Page() {
  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Title}>
        Результаты вступительных экзаменов по математике учеников minimath239 в
        сезоне 2020/2021
      </h1>

      <Date date={"24 сентября 2021"} />

      <p>
        Подвели итоги подготовки к поступлению в 5-е классы
        физико-математических лицеев Санкт-Петербурга и Москвы. Проходной балл
        на экзамене по математике в ФМЛ 239 в 2021 году составил 29 баллов.
        Четверо наших учеников смогли набрать больше.
        <br />
        Поздравляем всех ребят! Спасибо за ваш труд, упорство и интерес к
        математике.
      </p>

      <p>
        В таблице указаны школы, в пятые классы которых поступили наши ученики.
      </p>

      <Table>
        <tr>
          <th>№</th>
          <th>Город:</th>
          <th>Школа:</th>
          <th>Кол-во учеников:</th>
        </tr>

        <tr>
          <th>1</th>
          <th>СПБ</th>
          <th>ФМЛ 239</th>
          <th>2</th>
        </tr>

        <tr>
          <th>2</th>
          <th>СПБ</th>
          <th>610 школа</th>
          <th>1</th>
        </tr>

        <tr>
          <th>3</th>
          <th>СПБ</th>
          <th>ЛНМО</th>
          <th>1</th>
        </tr>

        <tr>
          <th>Итого:</th>
          <th />
          <th />
          <th>4</th>
        </tr>
      </Table>

      <h3 className={styles.Title}>
        Подробные результаты наших учеников, поступавших в 5 класс:
      </h3>

      <img src={"/images/achievements/2021_table_result.jpg"} alt={""} />

      <p>
        Желаем всем ребятам удачи на новом этапе вашей жизни.
        <br />
        Спасибо всем родителям за ваш выбор курса minimath239.
      </p>

      <p>
        Результаты за прошедшие 3 года:
        <List
          data={[
            "в ФМЛ 239 поступили 5 выпускников minimath239. В математическом центре при ФМЛ 239 учится 3 ученика и 3 поступают в этом году (P.S. в 2021 году в матцентр поступили 2 наших выпускника);",
            "в классическую гимназию №610, это где латынь и греческий, поступили 2 ученика;",
            "в школу 777 поступили 2 выпускника;",
            "в ФМЛ 30 поступил 1 ученик;",
            "в ФМЛ 366 поступил 1 выпускник;",
            "в ЛНМО 1 выпускник;",
            "в школу 1502 (Москва) поступил 1 выпускник;",
            "15 человек улучшили знания по математике и продолжили учиться в своих школах.",
          ]}
        />
      </p>

      <br />
    </div>
  );
}
