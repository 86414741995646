import { Link } from "react-router-dom";
import { CourseTypeEnum } from "../../constants/enums";
import styles from "./CoursesNavigationButtons.module.scss";

interface ICoursesNavigationButtonsProps {
  previousId: number | null;
  nextId: number | null;
  courseType: CourseTypeEnum;
}

export function CoursesNavigationButtons(
  props: ICoursesNavigationButtonsProps,
) {
  const { previousId, nextId, courseType } = props;

  return (
    <div className={styles.Container}>
      {!!previousId ? (
        <Link
          className={styles.Link}
          to={`/${courseType.toLowerCase()}/${previousId}`}
        >
          Предыдущий курс
        </Link>
      ) : (
        <></>
      )}

      {nextId ? (
        <Link
          className={styles.Link}
          to={`/${courseType.toLowerCase()}/${nextId}`}
        >
          Следующий курс
        </Link>
      ) : (
        <></>
      )}
    </div>
  );
}
