import ReactDOM from "react-dom/client";
import "./app/styles/index.scss";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import AuthProvider from "./context/authContext";
import { SnackbarProvider } from "notistack";
import SiteNameProvider from "./context/siteNameContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

console.log(
  "============================= started =============================",
);
console.dir(process.env, { depth: null });

root.render(
  <SnackbarProvider maxSnack={3}>
    <SiteNameProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </SiteNameProvider>
  </SnackbarProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
