import { TTableColumn } from "../../types/common/TableColumnType";

export const answer25Columns: TTableColumn[] = [
  {
    id: "course_data",
    label: "course_data",
    minWidth: 50,
    align: "left",
  },
  {
    id: "answer_date",
    label: "answer_date",
    minWidth: 50,
    align: "right",
  },
  {
    id: "course_id",
    label: "course_id",
    minWidth: 50,
    align: "right",
    linkTo: "/admin/course/edit/",
  },
  {
    id: "task_id",
    label: "task_id",
    minWidth: 50,
    align: "right",
    linkTo: "/admin/task/edit/",
  },
  {
    id: "count",
    label: "count",
    minWidth: 50,
    align: "right",
  },
  {
    id: "success",
    label: "success",
    minWidth: 50,
    align: "left",
  },

  {
    id: "user_answers",
    label: "user_answers",
    minWidth: 50,
    align: "left",
  },

  {
    id: "minimath_answer",
    label: "minimath_answer",
    minWidth: 50,
    align: "left",
  },

  {
    id: "question",
    label: "question",
    minWidth: 50,
    align: "left",
  },
];
