import { $host } from "./index";

export default class SchoolService {
  static async getSchoolList() {
    return await $host.get("school");
  }

  static async getSchoolById(id: string) {
    return await $host.get("school/" + id);
  }
}
