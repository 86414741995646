import { CourseTypeEnum } from "../constants/enums";
import { $host } from "./index";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

export type TPaginationArgs = {
  page: number;
  take: number;
};

export type TThemesArgs = TPaginationArgs & {
  courseType: CourseTypeEnum;
};
export default class CourseService {
  static async getThemesById(id: number, courseType: string) {
    return await $host.get(courseType.toLowerCase() + "/tasks/" + id);
  }

  static async getThemesList(args: TThemesArgs) {
    const { courseType, page, take } = args;
    return await $host
      .get(
        courseType.toLowerCase() + "/tasks",
        { params: { page, take } },
        { headers },
      )
      .catch((e: unknown) => {
        console.log(e);
      });
  }

  static async getCourseById(id: number) {
    return await $host.get("course/" + id.toString(), { headers });
  }

  // static async putUpdatedCourse(data: any) {
  //   return await $host.put("course/" + data.id, data, { headers });
  // }

  static async getThemeIds(courseType: CourseTypeEnum) {
    return await $host.get(courseType.toLowerCase() + "/all-ids", {
      headers,
    });
  }
}
