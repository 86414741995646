import React, { useState } from "react";
import { useEditContext } from "../../../context/editContext";
import {
  getPageDecorations,
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import { Button } from "../../../shared/ui/button";
import { EditTable } from "../../../widgets/editTable";

export function CreatePage(props: { table: string }) {
  const { siteNameList } = useSiteNameContext();
  const page =
    props.table === "course" ? "admin/create-course" : "admin/create-task";
  setDocumentTitle(siteNameList, page);
  const decorations = getPageDecorations(siteNameList, page);

  const { editItem, handleSubmit } = useEditContext();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const onSubmit = async (e: any) => {
    setButtonLoading(true);
    await handleSubmit(e).then(() => setButtonLoading(false));
  };

  return (
    <div className="container">
      <h1 dangerouslySetInnerHTML={{ __html: decorations?.header1 }}></h1>
      <p dangerouslySetInnerHTML={{ __html: decorations?.text1 }}></p>
      <hr />
      <form method="post" encType="multipart/form-data">
        <EditTable type={props.table} editItem={editItem} editMode={"create"} />

        <form className="form-group row justify-content-md-center">
          <div className="col-sm-6">
            <Button
              loading={buttonLoading}
              variant="contained"
              onClick={onSubmit}
            >
              Сохранить
            </Button>
          </div>
        </form>
      </form>
    </div>
  );
}
