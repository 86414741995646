import React from "react";
import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import LkForTeacherTable from "../../../components/lk/LkForTeacherTable";
import styles from "./lkForTeacherPage.module.scss";

export function LkForTeacherPage() {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "lk-for-teacher");

  return (
    <div className={styles.Wrapper}>
      <h3 className={styles.Title}>Личный кабинет учителя</h3>
      <LkForTeacherTable />
    </div>
  );
}
