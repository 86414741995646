import jwtDecode from "jwt-decode";
import { $host } from "./index";
import { getCookie, setCookie } from "react-use-cookie";
import { v4 } from "uuid";

export type AuthInput = {
  email: string;
  password: string;
};

export class AuthService {
  static loginUser = async (input: AuthInput) => {
    return await $host
      .post("login", {
        username: input.email.toLowerCase(),
        password: input.password,
      })
      .then((response: any) => {
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem(
          "auth",
          JSON.stringify(jwtDecode(response.data.access_token)),
        );
        return jwtDecode(response.data.access_token);
      });
  };
  static logout = () => {
    if (!isNaN(Number(getCookie("auth")))) {
      const parsedUserInfo =
        JSON.parse(localStorage.getItem("auth") || "") || undefined;
      setCookie("auth", parsedUserInfo.email || v4());
    }
    localStorage.removeItem("token");
    localStorage.removeItem("auth");
  };

  static register = async (input: AuthInput) => {
    return await $host
      .post("user", {
        email: input.email.toLowerCase(),
        password: input.password,
      })
      .then((response: any) => {
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem(
          "auth",
          JSON.stringify(jwtDecode(response.data.access_token)),
        );
        return jwtDecode(response.data.access_token);
      });
  };

  static confirmEmail = async (token: string) => {
    return await $host.post("user/confirm", { token }).then();
  };

  static forgotPassword = async (email: string) => {
    return await $host
      .post("user/send-reset-password-mail", { email })
      .then(() => {});
  };

  static changePassword = async (token: string, newPassword: string) => {
    return await $host
      .put("user/reset-password", {
        passwordResetToken: token,
        newPassword,
      })
      .catch((e: any) => console.log(e))
      .then(() => {});
  };
}
