import React from "react";
import { AchievementsContainer } from "../../../widgets/achievementsContainer";
import styles from "./achievementsPage.module.scss";

export function AchievementsPage() {
  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Title}>Наши достижения</h1>

      <AchievementsContainer />
    </div>
  );
}
