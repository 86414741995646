import { TTableColumn } from "../../types/common/TableColumnType";

export const answerLkColumns: TTableColumn[] = [
  {
    id: "date",
    label: "Дата",
    minWidth: 50,
    align: "left",
  },
  {
    id: "course_order",
    label: "Курс-задача-подзадача",
    minWidth: 50,
    align: "right",
  },
  {
    id: "task_id",
    label: "Код задачи",
    minWidth: 50,
    align: "right",
    linkTo: "/admin/task/edit/",
  },
  {
    id: "success",
    label: "Результаты",
    minWidth: 50,
    align: "right",
  },
  {
    id: "user_answer",
    label: "Ответы",
    minWidth: 50,
    align: "right",
  },
  {
    id: "question",
    label: "Текст задач",
    minWidth: 50,
    align: "left",
  },
];

export const studyLkColumns: TTableColumn[] = [
  {
    id: "course_id",
    label: "Курс",
    minWidth: 50,
    align: "left",
    linkTo: "/course/",
  },
  {
    id: "course_name",
    label: "Название",
    minWidth: 50,
    align: "left",
  },
  {
    id: "true_cnt",
    label: "Верно",
    minWidth: 50,
    align: "left",
  },
  {
    id: "wrong_cnt",
    label: "Неверно",
    minWidth: 50,
    align: "left",
  },
  {
    id: "empty_cnt",
    label: "Пусто",
    minWidth: 50,
    align: "left",
  },
  {
    id: "course_task_cnt",
    label: "Всего",
    minWidth: 50,
    align: "left",
  },
  {
    id: "create_date",
    label: "Дата",
    minWidth: 50,
    align: "left",
  },
];
