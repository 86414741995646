import styles from "./studyFlow.module.scss";

export function StudyFlow() {
  return (
    <div className={styles.wrapper}>
      <div>
        <h2 className={styles.title}>Как проходит обучение?</h2>
        <ol>
          <li>
            Мы обсуждаем желаемый результат на экзамене, составляем программу
            занятий, рекомендуем преподавателея, договариваемся о времени и
            частоте занятий
          </li>

          <li>
            {" "}
            На каждом занятии преподаватель объясняет одну или несколько тем
          </li>

          <li>
            По каждой теме ученик выполняет домашнюю работу, при необходимости
            получая консультации преподавателя или куратора в чате
          </li>

          <li>
            Периодически мы устраиваем небольшие контрольные, чтобы закрыть все
            пробелы
          </li>

          <li>
            По завершении курса ученик пишет несколько больших пробников,
            включающих практически все, что может встретиться на ЕГЭ
          </li>
        </ol>
      </div>
    </div>
  );
}
