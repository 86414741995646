import { TTableColumn } from "../../types/common/TableColumnType";

export const changePasswordColumns: TTableColumn[] = [
  {
    label: "Пароль",
    type: "password",
    id: "password",
  },
  {
    label: "Повторите пароль",
    type: "password",
    id: "repeatedPassword",
  },
];
