import React, { useRef } from "react";
import styles from "./Answer.module.scss";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { AnswerTypeEnum } from "../../constants/enums";
import { Input, Tooltip } from "@mui/material";

function AntAnswer(props: any) {
  const ref = useRef();

  return (
    <div>
      <Input
        bordered="true"
        autoComplete="off"
        prefix={
          props.status.length ? (
            <Tooltip title={"Введены запрещенные символы!"}>
              <ExclamationCircleOutlined rev={undefined} />
            </Tooltip>
          ) : (
            <></>
          )
        }
        className={classNames(
          props.type === AnswerTypeEnum.REG_EXP
            ? styles.antInputUnderlined
            : styles.antInput,
        )}
        {...props}
        {...ref}
        style={{ width: props.inputwidth }}
      ></Input>
    </div>
  );
}

export default AntAnswer;
