import React from "react";
import { TAnswerLkType } from "../../types/AnswerLkType";
import { answerLkColumns } from "../../constants/columns/AnswerLkTeacherColumns";
import CustomTableMaterial from "../../shared/ui/table/customTableMaterial";
import { RowsPerPageEnum } from "../../constants/vars/RowsPerPageOptions";

function AnswerLkTable(props: { tableData: TAnswerLkType[] }) {
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(RowsPerPageEnum.ALL);

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  return (
    <CustomTableMaterial<TAnswerLkType>
      list={props.tableData || []}
      page={page}
      columns={answerLkColumns}
      rowsPerPage={rowsPerPage}
      localPagination={true}
      // handleChangeRowsPerPage={handleChangeRowsPerPage}
      // handleChangePage={handleChangePage}
    />
  );
}

export default AnswerLkTable;
