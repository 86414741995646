import { TTableColumn } from "../../types/common/TableColumnType";

export const profileInformationColumns: TTableColumn[] = [
  {
    label: "Email",
    type: "email",
    id: "email",
  },
  {
    label: "Имя",
    type: "firstName",
    id: "firstName",
  },
  {
    label: "Фамилия",
    type: "lastName",
    id: "lastName",
  },
  {
    label: "Ник",
    type: "nickname",
    id: "nickname",
  },
];
