import styles from "./feedbackForm.module.scss";
import React, { ReactNode } from "react";
import { Paper } from "@mui/material";
import { Date, IDateProps } from "../../../shared/ui/date/date";

export interface IFeedbackFormProps extends IDateProps {
  fromWho: ReactNode;
  whyChose: ReactNode;
  goals: ReactNode;
  goalsHandling?: ReactNode;
  importance: ReactNode;
  liked: ReactNode;
  disliked: ReactNode;
  wishes: ReactNode;
  reviewImageSrc: string;
  studyingPeriod: string;
}

export function FeedbackForm(props: IFeedbackFormProps) {
  const {
    fromWho,
    whyChose,
    goals,
    goalsHandling,
    importance,
    liked,
    disliked,
    wishes,
    reviewImageSrc,
    date,
    editedDate,
    studyingPeriod,
  } = props;

  return (
    <Paper elevation={4} className={styles.Form}>
      <h3 className={styles.Title}>
        Отзыв о курсе индивидуальных занятий по математике Minimath поступление
        в 5 класс математического лицея
      </h3>

      <p>
        От: <strong>{fromWho}</strong>
      </p>

      <ol>
        <h4>
          <li>Почему выбрали курс Minimath?</li>
        </h4>

        <p>{whyChose}</p>

        <h4>
          <li>Какие цели ставили перед собой?</li>
        </h4>

        <p>{goals}</p>

        <h4>
          <li>Насколько курс Minimath отвечал вашим целям?</li>
        </h4>

        <p>{goalsHandling}</p>

        <h4>
          <li>Что важное для вас было в ходе обучения?</li>
        </h4>

        <p>{importance}</p>

        <h4>
          <li>Что больше всего понравилось в ходе обучения</li>
        </h4>

        <p>{liked}</p>

        <h4>
          <li>Что не понравилось и требует улучшения?</li>
        </h4>

        <p>{disliked}</p>

        <h4>
          <li>Пожелания будущим ученикам курса Minimath и их родителям</li>
        </h4>

        <p>{wishes}</p>
      </ol>

      <p>Период обучения: {studyingPeriod}</p>

      <Date date={date} editedDate={editedDate} />

      <img className={styles.ReviewImage} src={reviewImageSrc} alt="" />
    </Paper>
  );
}
