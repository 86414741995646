import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

type HookableFunc<T, P> = (param: P) => Promise<T | null>;
type HookedFuncResult<T> = {
  result: T | null;
  err: any;
  pending: boolean;
};

export const useLoadingHook = <T, P>(
  func: HookableFunc<T, P>,
  param: P,
): HookedFuncResult<T> => {
  const [result, setResult] = useState<T | null>(null);
  const [err, setErr] = useState<any>();
  const [pending, setPending] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setPending(true);
    func(param)
      .then(setResult)
      .catch((err) => {
        setErr(err);
        enqueueSnackbar(err.response.data.message || err.response.data.error, {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      })
      .finally(() => setPending(false));
  }, [param, func, enqueueSnackbar]);

  return {
    result,
    err,
    pending,
  };
};
