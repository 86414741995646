import { useState } from "react";
// import { useAuthContext } from "../../../context/authContext";
import Logo from "../../../shared/ui/logo/logo";
import { Toggler } from "../../../shared/ui/toggler";
import { NavBarNavigation } from "./navBarNavigation";
import styles from "./navBar.module.scss";

const links = [
  {
    to: "/",
    displayName: "Главная",
  },
  {
    to: "/exam",
    displayName: "Экзамены",
  },
  {
    to: "/theme",
    displayName: "Олимпиады",
  },
];

export function NavBar() {
  // const { isAuth } = useAuthContext();
  const [isDropDownToggled, toggleDropDown] = useState(false);
  const [isToggled] = useState(true);

  // @ts-ignore
  const authInfo = JSON.parse<TUserAuth>(localStorage.getItem("auth"));

  function mobileNavigation() {
    if (isDropDownToggled === true) {
      return (
        <NavBarNavigation
          links={links}
          authInfo={authInfo}
          onClick={() => toggleDropDown(!isDropDownToggled)}
        />
      );
    }
  }

  if (isToggled) {
    return (
      <div className={styles.NavBar}>
        <Logo className={styles.Logo} />

        <div className={styles.MobileDropDown}>
          <Logo />
          <div className={styles.MobileDropDown}>{mobileNavigation()}</div>
        </div>

        <div className={styles.MobileNavigation}>
          <Toggler onClick={() => toggleDropDown(!isDropDownToggled)} />
        </div>

        <div className={styles.DesktopNavigation}>
          <NavBarNavigation
            links={links}
            authInfo={authInfo}
            onClick={() => toggleDropDown(!isDropDownToggled)}
          />
        </div>
      </div>
    );
  }

  return <></>;
}
