import React from "react";
import { PageCard } from "../../../widgets/pageCard";
import styles from "./achievementsContainer.module.scss";

export function AchievementsContainer() {
  return (
    <>
      <div className={styles.Wrapper}>
        <PageCard
          title="Результаты вступительных экзаменов по математике учеников minimath239 в сезоне 2021/2022"
          date={" 20 августа 2022"}
          pageLink={"/achievements/2021-results"}
        >
          Подвели итоги подготовки к поступлению в 5-е классы
          физико-математических лицеев Москвы, Санкт-Петербурга и Томска.
          Четверо наших учеников смогли успешно сдать экзамены по математике в
          ФМЛ 239 и набрать 50, 45, 39 и 33 балла из 60 возможных. Поздравляем
          всех ребят! Спасибо за ваш труд, упорство и интерес к математике.
        </PageCard>

        <PageCard
          title={
            "Результаты вступительных экзаменов по математике учеников minimath239 в сезоне 2020/2021"
          }
          pageLink={"/achievements/2021-results"}
          date={"24 сентября 2021"}
        >
          Подвели итоги подготовки к поступлению в 5-е классы
          физико-математических лицеев Санкт-Петербурга и Москвы. Проходной балл
          на экзамене по математике в ФМЛ 239 в 2021 году составил 29 баллов.
          Четверо наших учеников смогли набрать больше. Поздравляем всех ребят!
          Спасибо за ваш труд, упорство и интерес к математике.
        </PageCard>

        <PageCard
          title={"Подвели итоги сезона подготовки 2019/2020"}
          pageLink={"/achievements/2020-results"}
          date={"1 сентября 2020"}
        >
          <p>
            Подвели итоги прошедшего сезона подготовки к поступлению в 5-е
            классы физико-математических лицеев Санкт-Петербурга и Москвы. Два
            наших ученика поступили в ФМЛ 239.
          </p>

          <p>
            Поздравляем всех ребят! Спасибо за ваш труд, упорство и интерес к
            математике.
          </p>
        </PageCard>

        <PageCard
          title={"«Бизнес предложение» для поступающих в 5-й класс ШГН"}
          pageLink={"/achievements/business-offer-5-grade"}
          date={"22 февраля 2020"}
        >
          <p>Уважаемые гости!</p>

          <p>
            Предлагаю усилить мотивацию при подготовке к экзамену по математике.
          </p>
        </PageCard>

        <PageCard
          title={
            "Изменения условий индивидуальных занятий на период январь - май 2020 года"
          }
          pageLink={"/achievements/changes-to-individual-lessons"}
          date={"1 января 2020"}
        >
          Уважаемые родители, вносим изменения в условия индивидуальных занятий
          на период январь-май 2020.
        </PageCard>

        <PageCard
          title={
            "Поздравляем нашего ученика Антона А. за победу в олимпиаде ЮМШ"
          }
          pageLink={"/achievements/congratulations-anton-a"}
          date={"31 декабря 2019"}
        >
          <p>
            Уважаемые родители, вносим изменения в условия индивидуальных
            занятий на период январь-май 2020.
          </p>
        </PageCard>

        <PageCard
          title={
            "Режим подготовки к поступлению в 5 класс. В чем разница, основные моменты"
          }
          pageLink={"/achievements/preparation-mode"}
          date={"18 июня 2019"}
        >
          <p>
            Часто родители ребят 3-4 классов спрашивают - нам пора, нам ещё рано
            или уже поздно начать готовиться к поступлению в 5 класс
            физико-математического лицея?
            <br />
            Поэтому вкратце о режиме подготовки.
          </p>
        </PageCard>

        <PageCard
          title={"Занятия летом"}
          pageLink={"/achievements/summer-lessons"}
          date={"15 июня 2019"}
        >
          <p>
            Летом индивидуальные занятия с Minimath.ru не заканчиваются.
            <br />
            Кто и с какими целями занимается летом?
          </p>
        </PageCard>

        <PageCard
          title={"Поздравляем наших учеников"}
          pageLink={"/achievements/congratulations-to-all-stundents"}
          date={"10 июня 2019"}
        >
          <p>
            Поздравляем всех наших учеников и их родителей с окончанием
            вступительных испытаний 2019!
          </p>
        </PageCard>

        <PageCard
          title={"Индивидуальные занятия Minimath239"}
          pageLink={"/achievements/individual-lessons"}
          date={"2 сентября 2018"}
        >
          <p>Отвечаем на вопросы родителей про индивидуальные занятия.</p>
        </PageCard>

        <PageCard
          title={"Ответы на вопросы по условиям проведения занятий"}
          pageLink={"/achievements/answers-to-lessons-conditions"}
          date={"1 сентября 2018"}
        />

        <PageCard title={"Тотальная проверка"} date={"12 мая 2018"}>
          <p>
            Благодаря нашим активным пользователям, провели тотальную проверку
            ошибок.
            <br />
            Все замечания учтены. Спасибо всем, кто помогал.
          </p>
        </PageCard>

        <PageCard
          title={"Первый анализ посещаемости нашего сайт"}
          date={"9 мая 2018"}
        >
          <p>
            Вчерашний День победы 9 мая отметили личной победой нашего проекта.
            <br />
            За время старта к нам пришли заниматься 1000 пользователей.
            <br />
            1000 уникальных посетителей за 1,5 месяца (выход в мир 25.03.2018)
            <br />
            Спасибо всем, кому было интересно и полезно даже в таком не полном
            функционале. Спасибо кто дал обратную связь.
          </p>
        </PageCard>

        <PageCard title={"Старт проекта"} date={"25 марта 2018"}>
          <p>
            Впереди два месяца подготовки к экзаменам. Мы решили не пропускать
            этот учебный сезон и открыли доступ к разделу вступительные работы
            239.
          </p>
        </PageCard>
      </div>
    </>
  );
}
