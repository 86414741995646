import React from "react";
import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import styles from "./notFoundPage.module.scss";

export function NotFoundPage() {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "404");

  return (
    <div className={styles.Background}>
      <img src={"/images/404.svg"} alt="" />
    </div>
  );
}
