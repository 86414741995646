import React from "react";
import styles from "../../../app/styles/scalableImage.module.scss";

function FmlSecretsSummary() {
  return (
    <>
      <h2>Итоги всероссийской олимпиады по математике 2021 года</h2>
      <p>
        Для примера я взял результаты всероссийской олимпиады по математике
        среди учеников 9-11 классов, которая завершилась 23 апреля 2021 в городе
        Тюмени{" "}
        <a href="https://olimp-tyuiu.ru/info">https://olimp-tyuiu.ru/info</a> и
        по фамилиям победителей нашел школы, в которых они учатся. ФМЛ 239
        получила столько же первых призов, сколько вся Москва в целом (по 7).
      </p>

      <img
        className={styles.Image}
        src="/images/summary-russian-math-olympiada_2021.png"
        alt={""}
      />
    </>
  );
}

export default FmlSecretsSummary;
