import React from "react";
import Date, { IDateProps } from "../date/date";
import styles from "./quote.module.scss";

interface IQuoteProps extends IDateProps {
  children: any;
  hrefToSource?: string;
  author?: string;
}

function Quote(props: IQuoteProps) {
  const { children, hrefToSource, author, date, editedDate } = props;

  return (
    <div className={styles.Quote}>
      <h2>{author}</h2>
      <p> {children}</p>
      <a className={styles.AuthorLink} href={hrefToSource}>
        {hrefToSource}
      </a>

      <Date date={date} editedDate={editedDate} />
    </div>
  );
}

export default Quote;
