import styles from "./Task.module.scss";
import TaskItem from "./TaskItem";
import React from "react";
import { TTask } from "../../types/TaskType";

type TTaskLayerProps = {
  tasksToShow: TTask[];
  isClickedCheck: boolean;
  answersList: any;
  checkedTasks: any;
};

function TaskItemLayer(props: TTaskLayerProps) {
  const { tasksToShow, isClickedCheck, answersList, checkedTasks } = props;
  let index = 1;
  let x = 1;
  return (
    <div>
      {tasksToShow.map((task) => (
        <div className={styles.TaskContainer} key={(x++).toString()}>
          <h1 className={styles.TaskNumber}>
            {index > 9
              ? !task.rootId
                ? index++
                : ""
              : !task.rootId
                ? "0" + index++
                : ""}
          </h1>

          <div className={styles.TaskLayer}>
            <TaskItem
              isCorrectSolved={
                checkedTasks.find((x: any) => x.taskId === task.id)
                  ?.isCorrect === "CORRECT"
              }
              key={task.id}
              answerList={answersList}
              isCheckButtonClicked={isClickedCheck}
              checked={checkedTasks.find((x: any) => x.taskId === task.id)}
              task={task}
            />

            {task.subTasks ? (
              task.subTasks.map((subtask) => (
                <>
                  <TaskItem
                    key={subtask.id}
                    isCorrectSolved={
                      checkedTasks.find((x: any) => x.taskId === task.id)
                        ?.isCorrect === "CORRECT"
                    }
                    answerList={answersList}
                    isCheckButtonClicked={isClickedCheck}
                    // forbiddenSymbols={hasForbiddenSymbols.find(x => x.taskId === task.id)}
                    checked={checkedTasks.find(
                      (x: any) => x.taskId === subtask.id,
                    )}
                    task={subtask}
                  />
                </>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default TaskItemLayer;
