import React from "react";
import Date from "../../../../shared/ui/date/date";
import List from "../../../../shared/ui/list/list";
import styles from "../../../Page.module.scss";

export function PreparationModePage() {
  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Title}>
        Режим подготовки к поступлению в 5 класс. В чем разница, основные
        моменты
      </h1>

      <Date date={"18 августа 2019"} />

      <p>
        Часто родители ребят 3-4 классов спрашивают - нам пора, нам ещё рано или
        уже поздно начать готовиться к поступлению в 5 класс
        физико-математического лицея?
        <br />
        Поэтому вкратце о режиме подготовки.
      </p>

      <p>
        Конечно же все зависит от уровня знаний ребенка, но... по нашей
        практике,
        <List
          data={[
            'Если ребенок начинает готовиться с третьего класса (не позднее третьей четверти, т.е. с  новогодних каникул ), то он занимается  в так называемом "спокойном режиме". Это два раза в неделю по 45 мин, с перерывами на каникулы и праздники.',
            "Если ребенок начинает заниматься с лета или с сентября 4го класса, то он работает в более жестком режиме - три раза в неделю по 1 часу, ежедневно на каникулах, подготовительные курсы, последний месяц почти домашнее обучение, последняя неделя по 2 часа ежедневно. В результате такой подготовки получается: изучить все темы, которых вообще не было в начальной школе вообще (комбинаторика, логика,  простые множители, Дирихле, инвариант, НОК и НОД, и др),  прорешать тесты вступительных работ прошлых лет по 2-3 круга, все задания с подготовительных курсов текущего и прошлых лет, задания математической олимпиады Карусель за несколько лет.",
          ]}
        />
      </p>

      <p>
        Фактически время окончания основной подготовки - это февраль месяц,
        когда ребенок идет на Подготовительные курсы фмл 239. Там он должен
        понимать все объяснения учителя, справляться с домашними заданиями, быть
        активным на занятии - отвечать у доски и уверенно аргументировать свои
        решения. После подготовительных курсов (февраль-апрель) остается месяц
        на повторение и углубление полученных знаний.
      </p>

      <p>
        У нас освободилось пять мест. С радостью ждем новых учеников и их
        родителей! Любовь к детям и к математике - наши основные ценности.
      </p>
    </div>
  );
}
