import React from "react";
import { Button } from "../../../../shared/ui/button";
import styles from "./courseName.module.scss";

export function CourseName(props: { header: string }) {
  return (
    <a href={`#${props.header}`}>
      <Button key={props.header} className={styles.Name}>
        {props.header}
      </Button>
    </a>
  );
}
