import { $host } from "./index";
import { TPaginationArgs } from "./CourseService";

const lkApi = "/lk";

export class LkService {
  static async getStudentListInfoForLkTeacher(args: TPaginationArgs) {
    return await $host.get(lkApi + "/student-teacher", { params: args });
  }

  static async getAnswerLkInfo(days: number) {
    return await $host.get(lkApi + "/answer-lk/" + days);
  }

  static async getLkInfo(id: string) {
    return await $host.get(lkApi + "/lk" + (id ? `/${id}` : ""));
  }

  static async getAnswer25Info(id: number, days: number) {
    return await $host.get(lkApi + "/answer25", { params: { id, days } });
  }
}
