import styles from "./note.module.scss";
import React from "react";

export interface INoteProps {
  children: any;
  title?: string;
}

export function Note(props: INoteProps) {
  const { children, title } = props;

  return (
    <div className={styles.NoteBackground}>
      <div className={styles.Note}>
        <p className={styles.NoteTitle}>{title}</p>
        <p className={styles.NoteMainText}>{children}</p>
      </div>
    </div>
  );
}
