export const rowsPerPageOptions = [
  {
    value: 1000000,
    label: "Все",
  },
];

export enum RowsPerPageEnum {
  ALL = 1000000,
}
