import { TableRow, Tooltip } from "@mui/material";
import React from "react";
import { TTableColumn } from "../../../types/common/TableColumnType";
import CustomTableCell from "./tableCellMaterial";

type TCustomTableRowProps = {
  row: any;
  columns: TTableColumn[];
  component?: any;
  linkTo?: string;
  tooltip?: string;
  onClick?: (e: any) => void;
};

function CustomTableRow<T>(props: TCustomTableRowProps) {
  const { row, columns, component, linkTo, tooltip, onClick } = props;
  return tooltip ? (
    <Tooltip
      title={tooltip}
      enterDelay={2000}
      enterNextDelay={2000}
      key={row.id || row.course_id}
      leaveDelay={0}
    >
      <TableRow
        onClick={onClick}
        component={component}
        linkTo={linkTo}
        hover
        tabIndex={-1}
        key={row.id || row.course_id}
      >
        {columns.map((column) => {
          const value = row[column.id as keyof T];
          return (
            <CustomTableCell
              key={column.id + (row.id || row.course_id)}
              column={column}
              rowNumber={row.id || row.course_id}
              value={value}
              linkTo={column.linkTo}
            />
          );
        })}
      </TableRow>
    </Tooltip>
  ) : (
    <TableRow
      onClick={onClick}
      component={component}
      linkTo={linkTo}
      hover
      tabIndex={-1}
      key={row.id}
    >
      {columns.map((column) => {
        const value = row[column.id as keyof T];
        return (
          <CustomTableCell
            column={column}
            rowNumber={row.id}
            value={value}
            linkTo={column.linkTo}
          />
        );
      })}
    </TableRow>
  );
}

export default CustomTableRow;
