import { Analytics } from "../../../widgets/analytics";
import { EntryWorks } from "../../../widgets/entryWorks";
import { Olympiades } from "../../../widgets/olympiades";
import { PageHeader } from "../../../widgets/pageHeader";
import { Research } from "../../../widgets/research";
import { Teacher } from "../../../widgets/teacher";
import styles from "./mainPage.module.scss";
import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";

export function MainPage() {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "main");

  return (
    <>
      <PageHeader
        title={
          <>
            Вы находитесь на тестовом сайте Minimath239. Для перехода на
            основной сайт нажмите{" "}
            <a
              className={styles.RedirectLink}
              href={"https://minimath239.com/"}
            >
              https://minimath239.com/
            </a>
          </>
        }
      />

      <div className={styles.Wrapper}>
        <EntryWorks />
        <Olympiades />
        <Teacher />
        <Research />
        <Analytics />
      </div>
    </>
  );
}
