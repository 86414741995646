import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import styles from "./date.module.scss";

export interface IDateProps {
  date?: string;
  editedDate?: string;
}

export function Date(props: IDateProps) {
  const { date, editedDate } = props;

  if (date === undefined) {
    return <></>;
  }

  if (editedDate === undefined) {
    return (
      <div>
        <p className={styles.DateText}>
          <FontAwesomeIcon className={styles.DateIcon} icon={faCalendarDays} />{" "}
          {date}
        </p>
      </div>
    );
  }

  return (
    <p className={styles.DateText}>
      <FontAwesomeIcon className={styles.DateIcon} icon={faCalendarDays} />{" "}
      {date} • Обновлено {editedDate}
    </p>
  );
}

export default Date;
