import FormFile from "../../../components/edit/FormFile";
import FormImage from "../../../components/edit/FormImage";
import FormLookup from "../../../components/edit/FormLookup";
import FormText from "../../../components/edit/FormText";
import FormTextArea from "../../../components/edit/FormTextArea";
import FormTiny from "../../../components/edit/FormTiny";
import { editCreateCourseColumns } from "../../../constants/columns/EditCreateCourseColumns";
import { editCreateTaskColumns } from "../../../constants/columns/EditCreateTaskColumns";
import { AnswerEditTypeEnum } from "../../../constants/enums";
import { CourseTypeEnum } from "../../../constants/enums";
import { useEditContext } from "../../../context/editContext";
import { getEnumKeyByEnumValue } from "../../../helpers/getEnumKeyByEnumValueHelper";
import { TEditCourse } from "../../../types/EditCourseType";
import { TEditTask } from "../../../types/EditTaskType";
import styles from "./editTable.module.scss";

export function EditTable(props: {
  editItem: TEditCourse | TEditTask;
  type: string;
  editMode?: "edit" | "create";
}) {
  const { editItem, type, editMode } = props;
  const attributes =
    type === "course" ? editCreateCourseColumns : editCreateTaskColumns;
  let x = 1;
  console.log("EditForm called");

  const {
    handleChange,
    handleLookupTypeAnswerChange,
    handleLookupCourseTypeChange,
    handleTinyMceFieldChange,
    uploadImage,
  } = useEditContext();
  return (
    <div className={styles.Container}>
      {attributes.map((item) => {
        if (item.type === "FormText") {
          return editMode === "edit" ? (
            <FormText
              key={(x++).toString()}
              title={item.displayName}
              name={item.name}
              id={item.name}
              value={
                editItem
                  ? editItem[item.name as keyof typeof editItem] || ""
                  : ""
              }
              onChange={handleChange}
              readOnly={item.readonly}
            />
          ) : (
            <></>
          );
        }
        if (item.type === "FormTextArea") {
          return editMode === "edit" ? (
            <FormTextArea
              key={(x++).toString()}
              title={item.displayName}
              name={item.name}
              id={item.name}
              value={
                editItem
                  ? editItem[item.name as keyof typeof editItem] || ""
                  : ""
              }
              onChange={handleChange}
              readOnly={item.readonly}
            />
          ) : (
            <></>
          );
        }
        if (item.type === "FormTiny") {
          return (
            <FormTiny
              key={(x++).toString()}
              title={item.displayName}
              onChange={handleTinyMceFieldChange}
              name={item.name}
              id={item.name}
              value={
                editItem
                  ? editItem[item.name as keyof typeof editItem] || ""
                  : ""
              }
            />
          );
        }
        if (item.type === "FormLookup" && item.name === "answerType") {
          return (
            <FormLookup
              key={(x++).toString()}
              onChange={handleLookupTypeAnswerChange}
              title={item.displayName}
              enum={AnswerEditTypeEnum}
              name={item.name}
              id={item.name}
              value={
                editItem
                  ? getEnumKeyByEnumValue(
                      AnswerEditTypeEnum,
                      editItem[item.name as keyof typeof editItem] || "",
                    )
                  : ""
              }
            />
          );
        }

        if (item.type === "FormLookup" && item.name === "urlCode") {
          return (
            <FormLookup
              key={(x++).toString()}
              onChange={handleLookupCourseTypeChange}
              title={item.displayName}
              enum={CourseTypeEnum}
              name={item.name}
              id={item.name}
              value={
                editItem
                  ? getEnumKeyByEnumValue(
                      CourseTypeEnum,
                      editItem[item.name as keyof typeof editItem] || "",
                    )
                  : ""
              }
            />
          );
        }

        if (item.type === "FormFile") {
          return (
            <FormFile
              key={(x++).toString()}
              title={item.displayName}
              name={item.name}
              id={item.name}
              value={
                editItem
                  ? editItem[item.name as keyof typeof editItem] || ""
                  : ""
              }
            />
          );
        }
        if (item.type === "FormImage") {
          return (
            <FormImage
              key={(x++).toString()}
              src={
                editItem && editItem[item.name as keyof typeof editItem]
                  ? editItem[item.name as keyof typeof editItem]?.toString()
                  : ""
              }
              alt=""
              onChange={uploadImage}
              title={item.displayName}
              name={item.name}
              id={item.name}
              base64CourseImage={editItem.base64CourseImage}
            />
          );
        }
        return (
          <FormImage
            key={(x++).toString()}
            src={
              editItem[item.name as keyof typeof editItem]
                ? editItem[item.name as keyof typeof editItem]?.toString()
                : ""
            }
            alt=""
            title={item.displayName}
            name={item.name}
            id={item.name}
          />
        );
      })}
    </div>
  );
}
