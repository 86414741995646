import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import styles from "./fefelovaPage.module.scss";

export function FefelovaPage() {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "fefelova");

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Layout}>
        <div className={styles.Text}>
          <h1> Уроки с преподавателем</h1>
          <p>Дорогие родители, спасибо, что пришли на наш сайт.</p>

          <p>
            Я, Фефелова Ольга, репетитор по математике. Готовлю ребят 7-11 лет к
            поступлению в математические классы и физико-математические лицеи.
          </p>
          <p>
            С июня 2017 года, мы небольшой командой
            математиков-единомышленников, выпускников факультета Прикладная
            математика Санкт-Петербургского государственного университета,
            разработали тренажер, который упрощает работу репетиторов и повышает
            эффективность подготовки ребят. Первая версия сайта находится по
            адресу minimath.ru и ориентирована на подготовку к поступлению в
            президентский физико-математический лицей №239, губернаторский
            физико-математичский лицей №30 и физико-математический лицей №366
            города Санкт-Петербурга.
          </p>
          <p>
            Недавно мы с мужем переехали в Москву и решили выпустить похожую
            версию, но уже для московских школ. С интересом изучаем опыт наших
            друзей, дети которых учатся в 57-й школе, в Летово, в “Новой школе”.
            Разбираем 30 учебников “Математика и информатика для 1-4 класса” от
            Наталии Сопруновой, Татьяны Рудченко, Марии и Семена Посицельских.
          </p>
          <p>
            На 14 сентября 2021 года доступна тестовая версия, которую мы
            активно улучшаем и в которой возможны небольшие перебои в работе.
          </p>
          <p>
            Приглашаем учеников на дистанционное обучение со мной и моими
            коллегами, а также на очные занятия у меня дома, в районе парка
            Музеон.
          </p>
          <p>С уважением, Фефелова Ольга</p>

          <div className={styles.InstagramLink}>
            <img src={"/images/insta_icon.svg"} alt="" />
            <a href={"https://www.instagram.com/o_fefel/"}>@o_fefel</a>
          </div>
        </div>

        <img className={styles.Image} src={"./images/olga.png"} alt="" />
      </div>
    </div>
  );
}
