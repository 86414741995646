import React from "react";
import Header from "../../../../shared/ui/header/header";
import List from "../../../../shared/ui/list/list";
import { TableOfContents } from "../../../../widgets/tableOfContents/ui/tableOfContents";
import styles from "./fML239BestSchoolPage.module.scss";

export function FML239BestSchoolPage() {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Page}>
        <div id="article">
          <Header
            title={
              "ФМЛ 239 – лучшая школа России. Мнение главного тренера сборной России по математике Кирилла Сухова"
            }
            date={"8 мая 2021"}
            editedDate="4 сентября 2021"
            imageSrc="/images/articles/article-header-test.png"
          />
          <h2>Просто лучшая школа России</h2>
          <h2>Как попасть в сборную России по математике</h2>
          <List
            data={[
              "Заниматься математикой",
              "Успешно пройти региональные этапы олимпиады",
              "Попасть в ТОП - 20(в своем классе) на Всероссийской олимпиаде школьников",
              "Поехать на осенние / зимние сборы(40 чел), пройти обучение и попасть в ТОП - 30",
              "Пройти отборочный тур осенью и зимой и попасть в ТОП - 15",
              "Участие в Румынской международной математической олимпиаде",
              "Участие во Всероссийской олимпиаде школьников по математике",
              "Пройти майские сборы и по итогам всех мероприятий попасть в 6 - ку лучших.",
            ]}
          />
          <h2>Талант или обученность?</h2>
          <p>
            "Очень большая обученность забьет почти любой талант без
            обученности"
          </p>
          <p>
            "Школьник, который хорошо занимался в кружке 5 лет (Матцентр
            ФМЛ239), точно будет выше, чем любой, очень талантливый школьник,
            которого чуть-чуть доучи и он будет уровня межнара, но которого не
            учили".
          </p>
          <h2>
            Может ли просто хорошо обученный ребенок попасть в сборную и
            получить золото?
          </h2>
          <p>
            "Да, может. Нужен здоровый баланс таланта, обученности и
            выносливости"
          </p>
        </div>

        <div>
          <TableOfContents />
        </div>
      </div>
    </div>
  );
}
