import React from "react";
import { Table } from "@mui/material";
import Header from "../../../shared/ui/header/header";
import { Note } from "../../../widgets/note";

function FinalOlympiadStageHeader() {
  return (
    <div>
      <Header
        title={
          "2022 год: Заключительный этап Всероссийской олимпиады по математике – ФМЛ 239 снова на коне!"
        }
        date={"6 мая 2022"}
        imageSrc="/images/articles/article-header-test.png"
      >
        <p>
          <strong>
            Вот и закончилась Всероссийская олимпиада школьников по математике.
            ФМЛ 239 получил 13 первых мест из 34.
          </strong>
        </p>
      </Header>
      <p>
        Олимпиада по математике в апреле 2022 года показала нам, что ФМЛ 239
        остается сильнейшей математической школой России: на заключительном
        этапе ученики из Президентского физико-математического лицея взяли 47
        дипломов. Плюс еще три диплома у учащихся ФТШ, занимающихся в МатЦентре
        239. Можно сравнить и со всеми городскими школами для наглядности, а так
        же со школами Москвы и регионов.
      </p>
      <Table>
        <tr>
          <th>Регион:</th>
          <th>Школа:</th>
          <th>Победитель:</th>
          <th>Призер:</th>
        </tr>

        <tr>
          <th>Санкт-Петербург</th>
          <th />
          <th>15</th>
          <th>40</th>
        </tr>

        <tr>
          <th>1</th>
          <th>239 ФМЛ</th>
          <th>13</th>
          <th>34</th>
        </tr>

        <tr>
          <th>2</th>
          <th>ФТШ</th>
          <th>1</th>
          <th>4</th>
        </tr>

        <tr>
          <th>3</th>
          <th>Лицей 533 ЮМШ</th>
          <th>1</th>
          <th>1</th>
        </tr>

        <tr>
          <th>4</th>
          <th>30 ФМЛ</th>
          <th />
          <th>1</th>
        </tr>

        <tr>
          <th>Москва</th>
          <th />
          <th>10</th>
          <th>56</th>
        </tr>

        <tr>
          <th>1</th>
          <th>Вторая школа (Л2Ш)</th>
          <th>5</th>
          <th>9</th>
        </tr>

        <tr>
          <th>2</th>
          <th>СУНЦ МГУ</th>
          <th>1</th>
          <th>5</th>
        </tr>

        <tr>
          <th>3</th>
          <th>Воробьевы Горы</th>
          <th>1</th>
          <th>5</th>
        </tr>

        <tr>
          <th>4</th>
          <th>179 школа</th>
          <th>1</th>
          <th>5</th>
        </tr>

        <tr>
          <th>5</th>
          <th>1568 школа (Пабло Неруды)</th>
          <th />
          <th>4</th>
        </tr>

        <tr>
          <th>6</th>
          <th>2007 ФМШ</th>
          <th />
          <th>4</th>
        </tr>

        <tr>
          <th>7</th>
          <th>2086 школа</th>
          <th>1</th>
          <th>3</th>
        </tr>

        <tr>
          <th>8</th>
          <th>57 школа</th>
          <th />
          <th>3</th>
        </tr>

        <tr>
          <th>9</th>
          <th>АНО ОШ ЦПМ</th>
          <th />
          <th>3</th>
        </tr>

        <tr>
          <th>10</th>
          <th>444 школа</th>
          <th />
          <th>2</th>
        </tr>

        <tr>
          <th>11</th>
          <th>1514 школа</th>
          <th>1</th>
          <th>2</th>
        </tr>

        <tr>
          <th>12</th>
          <th>ЛЕТОВО</th>
          <th />
          <th>2</th>
        </tr>

        <tr>
          <th>13</th>
          <th>Школа имени Чуйкова</th>
          <th />
          <th>2</th>
        </tr>

        <tr>
          <th>14</th>
          <th>Лицей НИУ ВШЭ</th>
          <th />
          <th>1</th>
        </tr>

        <tr>
          <th>15</th>
          <th>1798 школа (Феникс)</th>
          <th />
          <th>1</th>
        </tr>

        <tr>
          <th>16</th>
          <th>1580 школа (Бауманская)</th>
          <th />
          <th>1</th>
        </tr>

        <tr>
          <th>17</th>
          <th>1329 школа</th>
          <th />
          <th>1</th>
        </tr>

        <tr>
          <th>18</th>
          <th>1543 школа</th>
          <th />
          <th>1</th>
        </tr>

        <tr>
          <th>19</th>
          <th>1387 школа</th>
          <th />
          <th>1</th>
        </tr>

        <tr>
          <th>Московская область</th>
          <th />
          <th>1</th>
          <th>11</th>
        </tr>

        <tr>
          <th>1</th>
          <th>14 школа (Химки)</th>
          <th>1</th>
          <th></th>
        </tr>

        <tr>
          <th>2</th>
          <th>5 ФМЛ (Долгопрудный)</th>
          <th></th>
          <th>4</th>
        </tr>

        <tr>
          <th>3</th>
          <th>1 гимназия (Жуковский)</th>
          <th></th>
          <th>3</th>
        </tr>

        <tr>
          <th>4</th>
          <th>Физтех-лицей им. П. Л. Капицы</th>
          <th></th>
          <th>2</th>
        </tr>

        <tr>
          <th>5</th>
          <th>6 школа (Флёрова)</th>
          <th></th>
          <th>1</th>
        </tr>

        <tr>
          <th>6</th>
          <th>14 лицей (Жуковский)</th>
          <th></th>
          <th></th>
        </tr>

        <tr>
          <th>Республика Татарстан</th>
          <th />
          <th>2</th>
          <th>20</th>
        </tr>

        <tr>
          <th>1</th>
          <th>131 лицей (Казань)</th>
          <th>2</th>
          <th>4</th>
        </tr>

        <tr>
          <th>2</th>
          <th>26 гимназия (Набережные Челны)</th>
          <th></th>
          <th>5</th>
        </tr>

        <tr>
          <th>3</th>
          <th>СУНЦ IT-лицей КФУ</th>
          <th></th>
          <th>3</th>
        </tr>

        <tr>
          <th>4</th>
          <th>Лицей имени Н.И. Лобачевского КФУ</th>
          <th></th>
          <th>3</th>
        </tr>

        <tr>
          <th>5</th>
          <th>МАОУ СОШИ "Солнце"</th>
          <th></th>
          <th>1</th>
        </tr>

        <tr>
          <th>6</th>
          <th>МАОУ "Лицей-интернат №2"</th>
          <th></th>
          <th>3</th>
        </tr>

        <tr>
          <th>7</th>
          <th>МБОУ "Гимназия №122 имени Ж.А. Зайцевой</th>
          <th></th>
          <th>1</th>
        </tr>

        <tr>
          <th>Прочие регионы</th>
          <th />
          <th>6</th>
          <th>53</th>
        </tr>

        <tr>
          <th>1</th>
          <th>Пензенская область</th>
          <th>1</th>
          <th></th>
        </tr>

        <tr>
          <th>2</th>
          <th>Свердловская область</th>
          <th>1</th>
          <th>5</th>
        </tr>

        <tr>
          <th>3</th>
          <th>Новосибирская область</th>
          <th></th>
          <th>5</th>
        </tr>

        <tr>
          <th>4</th>
          <th>Челябинская область</th>
          <th>1</th>
          <th>4</th>
        </tr>

        <tr>
          <th>5</th>
          <th>Ярославская область</th>
          <th></th>
          <th>4</th>
        </tr>

        <tr>
          <th>6</th>
          <th>Республика Мордовия</th>
          <th></th>
          <th>4</th>
        </tr>

        <tr>
          <th>7</th>
          <th>Кировская область</th>
          <th>1</th>
          <th>3</th>
        </tr>

        <tr>
          <th>8</th>
          <th>Тюменская область</th>
          <th></th>
          <th>3</th>
        </tr>

        <tr>
          <th>9</th>
          <th>Республика Башкортостан</th>
          <th></th>
          <th>3</th>
        </tr>

        <tr>
          <th>10</th>
          <th>Ульяновская область</th>
          <th></th>
          <th>1</th>
        </tr>

        <tr>
          <th>11</th>
          <th>Пермский край</th>
          <th></th>
          <th>2</th>
        </tr>

        <tr>
          <th>12</th>
          <th>Вологодская область</th>
          <th></th>
          <th>2</th>
        </tr>

        <tr>
          <th>13</th>
          <th>Оренбургская область</th>
          <th></th>
          <th>2</th>
        </tr>
      </Table>

      <Note>
        Как мы видим, ФМЛ 239 беспрецедентный лидер среди других школ.
        Поздравляем победителей!
      </Note>

      <p>
        Почему ФМЛ 239 является сильнейшим физико-математическим лицеем и
        побеждает всех по итогам Всероссийской школьной олимпиады, мы
        рассказывали в прошлом году:
      </p>
      <a
        href={
          "/articles/sekrety-fml-239-ili-pochemu-litsej-pobezhdaet-moskvichej-na-olimpiadakh"
        }
      >
        minimath239.com/articles/sekrety-fml-239-ili-pochemu-litsej-pobezhdaet-moskvichej-na-olimpiadakh{" "}
      </a>
    </div>
  );
}

export default FinalOlympiadStageHeader;
