import { MathJax, MathJaxContext } from "better-react-mathjax";
import React from "react";

const config = {
  loader: { load: ["[tex]/html"] },
  tex: {
    packages: { "[+]": ["html"] },
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
  },
};

export const MathJaxOutput = (props: { text: any }) => {
  return typeof props.text === typeof "string" ? (
    <MathJaxContext config={config} version={3}>
      <MathJax
        dangerouslySetInnerHTML={{ __html: props.text }}
        style={{ textDecoration: "none" }}
        dynamic
        inline
      />
    </MathJaxContext>
  ) : (
    <MathJaxContext config={config} version={3}>
      <MathJax
        children={props.text}
        style={{ textDecoration: "none" }}
        dynamic
        inline
      />
    </MathJaxContext>
  );
};
