import React from "react";
import Date from "../../../../shared/ui/date/date";
import styles from "../../../Page.module.scss";

export function AnswersToLessonsPage() {
  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Title}>
        Ответы на вопросы по условиям проведения занятий
      </h1>

      <Date date={"1 сентября 2018"} />

      <p>
        Вопрос: в каком режиме будут проходить занятия (дни, время,
        периодичность)? Когда Вы готовы приступить к занятиям?
        <br />
        Ответ: любое удобное вам время дня. Периодичность (особенно вначале) на
        каждый 4-й день. Это максимально комфортный режим для запоминания
        материала ребенком.
      </p>

      <p>
        Вопрос: с чего начать?
        <br />
        Ответ: рекомендуем начать решать тесты вступительных работ на сайте.
        Начиная с 2009 и сохранять их.
        <br />
        Это будет хорошая основа, чтобы понять слабые стороны в знаниях ребенка
        и не пичкать его лишним, тем, что и так хорошо понимает.
      </p>

      <p>
        Вопрос: какие темы включены в курс занятий? На какую длительность
        рассчитан курс подготовки?
        <br />
        Ответ: длительность курса зависит от уровня подготовки ребенка, как он
        запоминает, как работает при повторениях. Курс можно прекратить в любой
        момент.
      </p>

      <p>
        Вопрос: как будет производиться оплата отдельно за каждый урок, либо
        сразу за весь курс?
        <br />
        Ответ: перевод на карту Сбербанка или на расчетный счет ИП Фефелова.
        Тоже как вам удобно за каждое занятие или за несколько.
      </p>

      <p>
        Вопрос: необходимо ли мое (мамы) присутствие на занятиях?
        <br />
        Ответ: присутствие родителя только первый раз, чтобы убедиться, что
        технически все работает.
      </p>

      <p>
        Вопрос: можно ли производить запись занятий для последующего просмотра?
        <br />
        Ответ: запись вести можно, лишь бы это не тормозило работу вашего
        компьютера. Все материалы занятий остаются на интерактивной доске, с
        которой можно сделать Pdf. документ или Jpeg.
      </p>

      <p>
        Вопрос: проверка ДЗ будет входить в тайминг онлайн урока ( 30 мин) ?
        Либо для проверки ДЗ выделяется отдельное время?
        <br />
        Ответ: заявленные 30 мин это только объяснение теории и алгоритма
        решения. Фактическое время занятий 40-45 мин. Домашние задания
        проверяются отдельно. Ребенок получает работу с исправлениями и
        подробными объяснениями. На основе ошибок из ДЗ строится занятия под
        новый урок.
      </p>

      <p>
        Вопрос: как понять, чему ребенок научился?
        <br />
        Ответ: для понимания прогресса по индивидуальным занятиям ребенка
        ведется диагностическая MindJet карта, на которой фиксируются пройденные
        темы, количество потраченного времени, количество ошибок. Карта доступна
        родителю и ребенку. С определенной периодичность ребенок решает
        проверочные тесты для закрепления навыков решения. Результаты тестов
        также фиксируются.
      </p>
    </div>
  );
}
