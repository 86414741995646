import { Button } from "../../shared/ui/button";
import { TCenteredButtonProps } from "../../types/common/CenteredButtonPropsType";

function ProfileCenteredButton(props: TCenteredButtonProps) {
  return (
    <div>
      {props.disabled ? (
        <Button type={"submit"} disabled onClick={props.onClick}>
          {" "}
          {props.label}
        </Button>
      ) : (
        <Button type={"submit"} onClick={props.onClick}>
          {" "}
          {props.label}
        </Button>
      )}
    </div>
  );
}

export default ProfileCenteredButton;
