import React from "react";
import Header from "../../../shared/ui/header/header";
import List from "../../../shared/ui/list/list";
import styles from "../../Page.module.scss";

export function ParentsPage() {
  return (
    <div className={styles.Wrapper}>
      <Header
        title="Подготовка к поступлению в 5 класс лучших школ России"
        imageSrc="/images/articles/article-header-test.png"
      ></Header>

      <p>
        <strong>Уважаемые родители,</strong>
      </p>

      <p>
        Наш текущий опыт основан на 5 годах подготовки к поступлению в ФМЛ 239.
      </p>

      <p>
        На наш взгляд, ФМЛ 239 использует вступительные экзамены в 5 класс для
        того, чтобы отобрать учеников, которые обладают следующими качествами:
      </p>

      <List
        data={[
          "Во-первых, имеют твердые базовые знания по 120 темам, т.е. доказали свое трудолюбие, усердие и способности;",
          "Во-вторых, умеют сконцентрироваться на 2 часа экзамена, т.е. доказали готовность к интенсивному умственному труду и психическим нагрузкам.",
        ]}
      />

      <p>
        Ребенок не должен быть гением. Он должен быть трудолюбивым, внимательным
        к ошибкам, стрессоустойчивым и знать больше школьной программы. На наш
        взгляд, почти любой ребенок может поступить в 5 класс ФМЛ 239, если
        начать с 1-2 класса и если родители готовы активно поддерживать обучение
        ребенка.
      </p>

      <p>
        Опираясь на эти требования, мы разработали методику, которая позволяет:
      </p>

      <List
        data={[
          'Учитывать вероятность появления тем на экзаменах и не тратить время на решение лишних" задач, которые дадут маленький рост вероятности успешного поступления;',
          'Проверять глубину понимания пройденных тем и отличать "понимание" от "запоминания";',
          "Находить слабые места и развивать проблемные навыки;",
          "Измерять и улучшать скорость решения задач и вычислений.",
        ]}
      />

      <p>
        Мы не учим «всей математике» и не натаскиваем на экзамен, а
        целенаправленно помогаем получить качественные знания и подготовиться к
        успешной сдаче вступительного экзамена в 5 класс Президентского
        физико-математического лицея №239 города Санкт-Петербурга и другие
        лучшие школы России.
      </p>

      <p>
        <strong>Бесплатный онлайн тренажер для учеников 3-4 класса</strong>
      </p>

      <List
        data={[
          "120 вариантов вступительных экзаменов в 5-е классы математических школ и топовые бесплатные кружки Москвы и Санкт-Петербурга.",
          "2000 задач в публичном доступе.",
          "2500 подсказок по популярным неправильным ответам.",
          "Решения интересных задач, для которых в интернете часто используется неоптимальный метод решения.",
          "Сбор статистики по всем введенным ответам.",
          "Отчет «Результаты», доступный всем, даже незарегистрированным ученикам.",
        ]}
      />

      <p>
        На наш взгляд, онлайн тренажер minimath239 отличное дополнение к
        математическим кружкам, обучению в семье или обучению с репетиторами:
      </p>

      <List
        data={[
          "170 000 проверенных ответов за сезон 2021/22.",
          "Пиковая посещаемость около 500 учеников в день.",
        ]}
      />

      <p>
        <b>Уроки с репетиторами для учеников 1-4 класса</b>
      </p>

      <p>
        Структурированная подготовка к поступлению в 5 класс физмат-школ с
        помощью тренажера и online преподавателей.
      </p>

      <List
        data={[
          "8000 задач онлайн тренажера для 2-8 класса.",
          "120 уроков для подготовки к 1 туру ФМЛ 239.",
          "7 уроков для подготовки к 2 туру ФМЛ 239.",
        ]}
      />

      <p>Стоимость занятий с преподавателями minimath239:</p>

      <List
        data={[
          "1500 руб. за индивидуальное занятие или ознакомительный урок;",
          "2000 руб. за группу из 2-х учеников;",
          "2500 руб. за группу из 3-х учеников.",
        ]}
      />

      <p>Результаты нашей работы.</p>

      <List
        data={[
          <p>
            В сезоне 2021/22 во 2-й тур ФМЛ 239 прошли 4 человека, набравшие в
            1-м туре 50, 45, 39 и 33 балла из 60 возможных. Подробности{" "}
            <a href="https://minimath.ru/news/results2022">тут</a>
          </p>,
          <p>
            Итоги предыдущих сезонов: за{" "}
            <a href="https://minimath.ru/news/results2021">2021</a>, за{" "}
            <a href="https://minimath.ru/news/results2020">2020</a>, за{" "}
            <a href="https://minimath.ru/news/results2019">2019</a>.
          </p>,
          <p>
            Отзывы родителей: <a href="/feedback">тут</a>.
          </p>,
        ]}
      />

      <p>
        Для начала обучения необходимо послать заявку на проведение
        ознакомительного урока на e-mail{" "}
        <a href="mailto:minimath239@yandex.ru">minimath239@yandex.ru</a> или
        телеграмм:{" "}
        <a href="https://telegram.me/Fefelova_OM">+7 (981) 682-86-83</a>.
      </p>

      <p>
        Наша цель – дать вам технологию обучения и значительно повысить шансы
        вашего ребенка на поступление в лучшие физико-математические школы
        России.
      </p>

      <p>С уважением,</p>

      <p>Команда minimath239</p>
    </div>
  );
}

