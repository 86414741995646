import { useEffect, useState } from "react";

export interface Heading {
  id: string;
  text: string;
  level: number;
}

export function useTableOfContents(selector: string): Heading[] {
  const [headings, setHeadings] = useState<Heading[]>([]);

  useEffect(() => {
    const elements = document.querySelectorAll(selector);
    const headings: Heading[] = Array.from(elements).map((element) => {
      let id = element.id;
      const text = element.textContent || "";
      const level = parseInt(element.nodeName.replace("H", ""), 10);

      if (!id) {
        id = text.replace(/\s+/g, "-").toLowerCase();
        element.id = id;
      }

      return { id, text, level };
    });

    setHeadings(headings);
  }, [selector]);

  return headings;
}
